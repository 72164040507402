import { format } from "date-fns";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import btnExport from "../assets/btnExport";
import BTNElimina from "../components/BTNElimina";
import { inEuro } from "../components/inEuro";
import PageBody from "../components/PageBody";
import Sintesi from "../components/Sintesi";
import Grigliata from "../components/TableNew";
import { useCommesse } from "../stores/useCommesse";
import { useSocieta } from "../stores/useSocieta";
import { useUser } from "../stores/useUser";

const Commesse = () => {
  const token = useUser((state) => state.token);
  const commesse = useCommesse((state) => state.commesse);
  const retrieve = useCommesse((state) => state.retrieve);
  const create = useCommesse((state) => state.create);
  const remove = useCommesse((state) => state.remove);
  const societa = useSocieta((state) => state.societa);

  const promptRemove = useCallback(
    (token, id, nome) => {
      if (window.confirm(`Sei sicuro di voler eliminare "${nome}"?`)) {
        remove(token, id);
      }
    },
    [remove]
  );

  const columns = [
    { field: "Id", hide: true },
    { field: "Società" },
    { field: "Nome" },
    { field: "% Sconto" },
    { field: "€ Sconto" },
    { field: "Importo" },
    { field: "Da pagare" },
    { field: "Dal" },
    { field: "Anni" },
    { field: "Data" },
    { field: "Acquisto" },
    { field: "Accettata" },
    { field: "Note" },
    { field: "Azioni", filter: false, sort: false, cellRenderer: BTNElimina },
  ];

  const data = useMemo(() => {
    try {
      return commesse.map((commessa) => {
        return {
          Id: commessa?.id,
          Società: commessa?.societa?.nome,
          Nome: commessa?.nome,
          "% Sconto": `${commessa?.sconto} %`,
          Importo: commessa?.importo ? inEuro(commessa?.importo) : "NN",
          "€ Sconto": commessa?.importo
            ? inEuro((commessa?.importo * commessa?.sconto) / 100)
            : "NN",
          "Da pagare": inEuro(
            commessa?.importo - (commessa?.importo * commessa?.sconto) / 100
          ),
          Dal: commessa?.annualita_inizio,
          Anni: commessa?.quanti_anni,
          Data: format(new Date(commessa?.data), "dd/MM/yyyy"),
          Acquisto: commessa?.modalita_acquisto,
          Accettata: commessa?.accettata ? true : false,
          Note: commessa?.note,
          Elimina: () => promptRemove(token, commessa.id, commessa.nome),
        };
      });
    } catch (error) {
      return [];
    }
  }, [commesse, promptRemove, token]);

  useEffect(() => {
    if (token) {
      retrieve(token);
    }
  }, [token, retrieve]);

  const Form = () => {
    const HandleSublit = async (e) => {
      e.preventDefault();
      if (nome.current.value !== "") {
        const _societa_id = societa_id.current.value;
        const _nome = nome.current.value;
        const _importo = importo.current.value;
        const _sconto = sconto.current.value;
        const _annualita_inizio = annualita_inizio.current.value;
        const _quanti_anni = quanti_anni.current.value;
        const _data = data.current.value;
        const _modalita_acquisto = modalita_acquisto.current.value;
        const _accettata = accettata.current.checked;
        const _note = note.current.value;

        const _body = {
          societa_id: _societa_id,
          nome: _nome,
          importo: _importo,
          sconto: _sconto,
          annualita_inizio: _annualita_inizio,
          quanti_anni: _quanti_anni,
          data: _data,
          modalita_acquisto: _modalita_acquisto,
          accettata: _accettata,
          note: _note,
        };

        try {
          const _out = await create(token, _body);
        } catch (error) {
          window.alert("Errore: " + error);
        }
      }
    };

    const societa_id = useRef();
    const nome = useRef();
    const importo = useRef();
    const sconto = useRef();
    const annualita_inizio = useRef();
    const quanti_anni = useRef();
    const data = useRef();
    const modalita_acquisto = useRef();
    const accettata = useRef();
    const note = useRef();

    return (
      <form method="post" onSubmit={HandleSublit} className="flex-row">
        <p className="py-1">Nome:</p>
        <input
          ref={nome}
          type="text"
          placeholder="Nome"
          className="w-full rounded border p-2"
        />
        <p className="py-1">Società:</p>
        <select ref={societa_id} className="w-full rounded border p-2">
          {societa.map((s) => (
            <option key={s.id} value={s.id}>
              {s.nome}
            </option>
          ))}
        </select>
        <p className="py-1">Importo:</p>
        <input
          ref={importo}
          type="number"
          placeholder="Importo"
          className="w-full rounded border p-2"
        />
        <p className="py-1">Sconto:</p>
        <input
          ref={sconto}
          type="number"
          placeholder="Sconto"
          className="w-full rounded border p-2"
        />
        <p className="py-1">Annualità d'inizio:</p>
        <input
          ref={annualita_inizio}
          type="number"
          placeholder="Annualità d'inizio"
          className="w-full rounded border p-2"
        />
        <p className="py-1">Quanti anni:</p>
        <input
          ref={quanti_anni}
          type="number"
          placeholder="Quanti anni"
          className="w-full rounded border p-2"
        />
        <p className="py-1">Data:</p>
        <input
          ref={data}
          type="date"
          placeholder="Data"
          className="w-full rounded border p-2"
        />
        <p className="py-1">Modalità acquisto:</p>
        <input
          ref={modalita_acquisto}
          type="text"
          placeholder="Modalità acquisto"
          className="w-full rounded border p-2"
        />
        <div className="flex flex-row p-2">
          <p className="">Accettata:</p>
          <input
            ref={accettata}
            type="checkbox"
            className="w-full rounded border p-2"
          />
        </div>
        <p className="py-1">Note:</p>
        <input
          ref={note}
          type="text"
          placeholder="Note"
          className="w-full rounded border p-2"
        />
        <button
          type="submit"
          className="bg-futura-scuro mt-6 w-full rounded border p-2 text-white"
        >
          Aggiungi
        </button>
      </form>
    );
  };

  const sintesi = useMemo(() => {
    if (commesse) {
      const _sintesi = [
        { k: "Commesse", v: commesse.length },
        {
          k: "% Sconto medio",
          v: `${(
            commesse.reduce((acc, curr) => acc + curr.sconto, 0) /
            commesse.length
          ).toFixed(2)} %`,
        },
        {
          k: "€ Sconto",
          v: inEuro(
            commesse.reduce(
              (acc, curr) => acc + (curr.importo * curr.sconto) / 100,
              0
            )
          ),
        },
        {
          k: "Importo",
          v: inEuro(commesse.reduce((acc, curr) => acc + curr.importo, 0)),
        },
        {
          k: "Da pagare",
          v: inEuro(
            commesse.reduce(
              (acc, curr) =>
                acc + (curr.importo - (curr.importo * curr.sconto) / 100),
              0
            )
          ),
        },
      ];
      return _sintesi;
    }
    return [];
  }, [commesse]);

  const gridRef = useRef();

  const onBtnExport = useCallback(() => {
    btnExport(gridRef, "Commesse", [
      "Società",
      "Nome",
      "% Sconto",
      "€ Sconto",
      "Importo",
      "Da pagare",
      "Dal",
      "Anni",
      "Data",
      "Acquisto",
      "Accettata",
      "Note",
    ]);
  }, []);

  return (
    <PageBody>
      <Grigliata
        data={data}
        form={<Form />}
        gridRef={gridRef}
        name="Utilizzo"
        actionCreate={create}
        onBtnExport={onBtnExport}
        columns={columns}
        sintesi={<Sintesi data={sintesi} />}
      />
    </PageBody>
  );
};

export default Commesse;
