import React, { useCallback, useEffect, useMemo, useRef } from "react";
import btnExport from "../assets/btnExport";
import PageBody from "../components/PageBody";
import Grigliata from "../components/TableNew";
import { useAziende } from "../stores/useAziende";
import { useUser } from "../stores/useUser";
import BTNElimina from "../components/BTNElimina";

const Aziende = () => {
  const token = useUser((state) => state.token);
  const getAz = useAziende((state) => state.getAz);
  const aziende = useAziende((state) => state.aziende);
  const delAz = useAziende((state) => state.delAz);
  const postAz = useAziende((state) => state.postAz);

  const promptRemove = useCallback(
    (token, id, nome) => {
      if (window.confirm(`Sei sicuro di voler eliminare "${nome}"?`)) {
        delAz(token, id);
      }
    },
    [delAz]
  );

  useEffect(() => {
    getAz(token);
  }, [token, getAz]);

  const columns = [
    { field: "id" },
    { field: "Azienda", flex: 1 },
    { field: "Azioni", filter: false, sort: false, cellRenderer: BTNElimina },
  ];

  const data = useMemo(() => {
    return aziende.map((azienda) => {
      return {
        id: azienda.id,
        Azienda: azienda.name,
        Elimina: () => promptRemove(token, azienda.id, azienda.name),
      };
    });
  }, [aziende, promptRemove, token]);

  const Form = () => {
    const HandleSublit = async (e) => {
      e.preventDefault();
      if (name.current.value !== "") {
        const _name = name.current.value.trim();
        await postAz(token, _name);
      }
    };

    const name = React.useRef();

    return (
      <form method="post" onSubmit={HandleSublit} className="flex-row">
        <p className="py-1">Nome azienda:</p>
        <input
          ref={name}
          type="text"
          placeholder="Nome"
          className="w-full rounded border p-2"
        />
        <button
          type="submit"
          className="bg-futura-scuro mt-6 w-full rounded border p-2 text-white"
        >
          Aggiungi
        </button>
      </form>
    );
  };

  const gridRef = useRef();

  const onBtnExport = useCallback(() => {
    btnExport(gridRef, "Aziende cessionarie", ["Azienda"]);
  }, []);

  return (
    <PageBody title="Aziende">
      <Grigliata
        data={data}
        form={<Form />}
        columns={columns}
        gridRef={gridRef}
        name="Cessionaria"
        actionCreate={postAz}
        onBtnExport={onBtnExport}
      />
    </PageBody>
  );
};

export default Aziende;
