import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import React from "react";
import useDashboard from "../stores/useDashboard";

const YSelect = () => {

    const year = useDashboard((state) => state.year);
    const setYear = useDashboard((state) => state.setYear);

    return (
        <div className="flex flex-row gap-3 rounded bg-futura-blu text-futura-bianco items-center cursor-default">
            <div onClick={() => setYear(year - 1)} className="p-2 cursor-pointer">
                <ArrowLeft2 size="18" color="#f4f4f5" />
            </div>
            <span className="text-base">{year}</span>
            <div onClick={() => setYear(year + 1)} className="p-2 cursor-pointer">
                <ArrowRight2 size="18" color="#f4f4f5" />
            </div>
        </div>
    );

};

export default YSelect;