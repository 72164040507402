import React from "react";

const PageBody = ({ children }) => {
    return (
        <div className="flex flex-1 flex-col bg-white">
            <div className='m-2 flex-1 flex flex-col'>
                {children}
            </div>
        </div>
    );
}

export default PageBody;