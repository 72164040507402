import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import PropTypes from "prop-types";
import { AgGridReact } from "@ag-grid-community/react";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import AG_GRID_LOCALE_IT from "../assets/locale.it";
import Modal from "./Modal";
import { When } from "react-if";

const gridOptions = {
  autoSizeStrategy: {
    type: "fitGridWidth",
  },
  defaultColDef: {
    sortable: true,
    filter: true,
    sort: true,
    resizable: true,
    cellStyle: { whiteSpace: "normal" },
  },
};

const Grigliata = ({
  columns,
  data,
  gridRef,
  name,
  form,
  sintesi,
  onBtnExport,
}) => (
  <>
    <div className="flex flex-row justify-between m-2">
      <div className="flex flex-row gap-2">
        <Modal
          title={`Aggiungi ${name}`}
          buttonLabel={`Aggiungi ${name}`}
          buttonClass="rounded bg-futura-blu px-4 py-2 cursor-pointer text-futura-bianco"
        >
          {form}
        </Modal>
        <When condition={sintesi !== undefined}>
          <Modal
            title="Sintesi"
            buttonLabel="Sintesi"
            buttonClass="rounded bg-futura-blu px-4 py-2 cursor-pointer text-futura-bianco"
          >
            {sintesi}
          </Modal>
        </When>
      </div>
      <div className="flex flex-row gap-2">
        <div
          className="rounded bg-futura-blu px-4 py-2 cursor-pointer text-futura-bianco"
          onClick={onBtnExport}
        >
          Esporta Excel
        </div>
      </div>
    </div>
    <div className="ag-theme-quartz flex-1 p-1">
      <AgGridReact
        ref={gridRef}
        rowData={data}
        columnDefs={columns}
        ensureDomOrder={true}
        gridOptions={gridOptions}
        enableCellTextSelection={true}
        localeText={AG_GRID_LOCALE_IT}
        modules={[CsvExportModule, ClientSideRowModelModule]}
      />
    </div>
  </>
);

Grigliata.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  gridRef: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  sintesi: PropTypes.object,
  onBtnExport: PropTypes.func.isRequired,
};

export default Grigliata;
