import { clear, del, get, set } from "idb-keyval";

const storage = {
  getItem: async (name) => {
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    await set(name, value);
  },
  removeItem: async (name) => {
    await del(name);
  },
};

const clearStorage = async () => {
  await clear();
};

export default storage;
export { clearStorage };
