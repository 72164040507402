import { ClipboardText, Maximize } from "iconsax-react";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { toast } from "react-hot-toast";
import btnExport from "../assets/btnExport";
import BTNElimina from "../components/BTNElimina";
import PageBody from "../components/PageBody";
import { _nrand, _rand } from "../components/rand";
import Grigliata from "../components/TableNew";
import { useAziende } from "../stores/useAziende";
import { useSocieta } from "../stores/useSocieta";
import { useUser } from "../stores/useUser";

const CopyLink = ({ data }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(data.Link);
    toast.success("Link copiato");
  };

  return (
    <div className="flex flex-row items-center gap-2 underline">
      <div onClick={copyToClipboard} className="cursor-pointer" title="Copia">
        <ClipboardText size="32" color="#0284c7" />
      </div>
      <a title="Apri" rel="noreferrer" href={data.Link} target="_blank">
        <Maximize size="32" color="#0284c7" />
      </a>
    </div>
  );
};

const Societa = () => {
  const token = useUser((state) => state.token);
  const societa = useSocieta((state) => state.societa);
  const retrieve = useSocieta((state) => state.retrieve);
  const create = useSocieta((state) => state.create);
  const remove = useSocieta((state) => state.remove);

  const aziende = useAziende((state) => state.aziende);

  const promptRemove = useCallback(
    (token, id, nome) => {
      if (window.confirm(`Sei sicuro di voler eliminare "${nome}"?`)) {
        remove(token, id);
      }
    },
    [remove]
  );

  const columns = [
    { field: "Id", hide: true },
    { field: "Società" },
    { field: "Per conto di" },
    { field: "Link", filter: false, sort: false, cellRenderer: CopyLink },
    { field: "Pin" },
    { field: "Attivo" },
    { field: "Azioni", filter: false, sort: false, cellRenderer: BTNElimina },
  ];

  const data = useMemo(() => {
    try {
      return societa.map((societa) => {
        return {
          Id: societa.id,
          "Per conto di": aziende.find(
            (azienda) => azienda.id === societa.aziende_id
          ).name,
          Società: societa.nome,
          //codice: societa.codice,
          Link: societa.link,
          Pin: societa.pin,
          Attivo: societa.attivo ? true : false,
          Elimina: () => promptRemove(token, societa.id, societa.nome),
        };
      });
    } catch (error) {
      return [];
    }
  }, [aziende, promptRemove, societa, token]);

  useEffect(() => {
    if (token) {
      retrieve(token);
    }
  }, [token, retrieve]);

  const Form = () => {
    const HandleSublit = (e) => {
      e.preventDefault();
      if (name.current.value !== "") {
        const _name = name.current.value.trim();
        const _attivo = attivo.current.checked;
        const _cess = cess.current.value;
        const _codice = _rand(32);
        const _link = document.URL.split("#")[0] + "#/carica/" + _codice;
        const _body = {
          aziende_id: _cess,
          nome: _name,
          codice: _codice,
          link: _link,
          pin: _nrand(6),
          attivo: _attivo,
        };
        create(token, _body);
      }
    };

    const name = useRef();
    const cess = useRef();
    const attivo = useRef();

    return (
      <form method="post" onSubmit={HandleSublit} className="flex-row">
        <p className="py-1">Nome azienda cessionaria:</p>
        <select ref={cess} className="w-full rounded border p-2">
          {aziende.map((azienda) => {
            return (
              <option key={azienda.id} value={azienda.id}>
                {azienda.name}
              </option>
            );
          })}
        </select>
        <p className="py-1">Nome società cedente:</p>
        <input
          ref={name}
          type="text"
          placeholder="Nome"
          className="w-full rounded border p-2"
        />
        <div className="flex flex-row p-2">
          <p className="">Attiva:</p>
          <input
            ref={attivo}
            type="checkbox"
            className="w-full rounded border p-2"
          />
        </div>
        <button
          type="submit"
          className="bg-futura-scuro mt-6 w-full rounded border p-2 text-white"
        >
          Aggiungi
        </button>
      </form>
    );
  };

  const gridRef = useRef();

  const onBtnExport = useCallback(() => {
    btnExport(gridRef, "Società Cedenti", [
      "Società",
      "Per conto di",
      "Link",
      "Pin",
      "Attivo",
    ]);
  }, []);

  return (
    <PageBody>
      <Grigliata
        data={data}
        form={<Form />}
        gridRef={gridRef}
        name="Cedente"
        actionCreate={create}
        columns={columns}
        onBtnExport={onBtnExport}
      />
    </PageBody>
  );
};

export default Societa;
