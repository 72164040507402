import React, { memo } from "react";

const Sintesi = ({ data }) => {
    return (
        <div className="flex flex-row gap-6 max-h-96 overflow-auto m-1">
            <div>
                {data.map((d) => (
                    <div key={d.k} className="flex flex-row gap-2 my-2 f-1">
                        <strong className="px-2 py-1">{d.k}</strong>
                    </div>
                ))}
            </div>
            <div>
                {data.map((d) => (
                    <div key={d.v} className="flex flex-row gap-2 my-2 f-1">
                        <p className="px-2 py-1">{d.v}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default memo(Sintesi);