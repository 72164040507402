import { toast } from "react-hot-toast"

const _get = async (token, url) => {
    const response = await fetch(`${process.env.REACT_APP_URL}/${url}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    try {
        const data = await response.json()
        if (response.ok && data) {
            return data
        } else {
            throw new Error(data.error)
        }
    } catch (error) {
        toast.error("Errore nel caricare i dati.")
        throw new Error(error)
    }
}

const _post = async (token, url, body) => {
    const response = await fetch(`${process.env.REACT_APP_URL}/${url}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
    })
    try {
        const data = await response.json()
        if (response.ok && data) {
            toast.success("Informazione salvata con successo.")
            return data
        } else {
            throw new Error(data.error)
        }
    } catch (error) {
        toast.error("Errore nel salvataggio dei dati.")
        throw new Error(error)
    }
}

const _delete = async (token, url, id) => {
    const response = await fetch(`${process.env.REACT_APP_URL}/${url}/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    try {
        const data = await response.json()
        if (response.ok && data) {
            toast.success("Dato eliminato con successo.")
            return data
        } else {
            throw new Error(data.error)
        }
    } catch (error) {
        toast.error("Errore nell'eliminazione del dato.")
        throw new Error(error)
    }
}

const _patch = async (token, url, id, body) => {
    const response = await fetch(`${process.env.REACT_APP_URL}/${url}/${id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
    })
    try {
        const data = await response.json()
        if (response.ok && data) {
            toast.success("Dato modificato con successo.")
            return data
        } else {
            throw new Error(data.error)
        }
    } catch (error) {
        toast.error("Errore nella modifica del dato.")
        throw new Error(error)
    }
}

const _upload = async (token, url, file) => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.fileName);
        const response = await fetch(`${process.env.REACT_APP_URL}/${url}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        })
        const data = await response.json()
        if (response.ok && data) {
            toast.success(`File ${file.name} caricato con successo.`)
            return data
        } else {
            throw new Error(data.error)
        }
    } catch (error) {
        toast.error(`Errore nel caricamento di ${file.name}.`)
    }
}


export { _get, _post, _delete, _patch, _upload};