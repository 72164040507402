import React from 'react';
import { Toaster } from 'react-hot-toast';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import Contratti from './views/Contratti';
import Aziende from './views/Aziende';
import Caronte from './views/Caronte';
import Categorie from './views/Categorie';
import Commesse from './views/Commesse';
import DashBoard from './views/Dashboard';
import Documenti from './views/Documenti';
import Esplora from './views/Esplora';
import HomePage from './views/HomePage';
import Inserisci from './views/Inserisci';
import Login from './views/Login';
import Pratiche from './views/Pratiche';
import Societa from './views/Societa';
import Utilizzi from './views/Utilizzi';

const App = () => {
  return (
    <div className="w-screen h-screen">
      <HashRouter>
        <Routes>
          <Route element={<Login />} path="login" />
          <Route element={<HomePage />} path="app">
            <Route index element={<DashBoard />} path="dashboard" />

            <Route element={<Aziende />} path="aziende" />
            <Route element={<Societa />} path="societa" />
            <Route element={<Contratti />} path="contratti" />

            <Route element={<Categorie />} path="categorie" />
            <Route element={<Documenti />} path="documenti" />
            <Route element={<Pratiche />} path="pratiche" />

            <Route element={<Utilizzi />} path="utilizzi" />
            <Route element={<Commesse />} path="commesse" />
            <Route element={<Esplora />} path="esplora" />
            <Route index element={<Navigate to={'/app/dashboard'} />} path="*" />
          </Route>
          <Route element={<Inserisci />} path="carica/:codice" />
          <Route element={<Caronte />} path="*" />
        </Routes>
      </HashRouter>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: '#ecfdf5',

            },
          },
          error: {
            style: {
              background: '#FFF1F2',
            },
          },
        }} />
    </div>
  );
}

export default App;
