import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { _delete, _get, _post } from "../components/fetchers";
import storage from "./IDB";

export const useUtilizzi = create(
  persist(
    (set, get) => ({
      utilizzi: [],
      retrieve: async (token) => {
        try {
          const _utilizzi = await _get(token, "utilizzi");
          set({ utilizzi: _utilizzi });
        } catch (error) {
          throw new Error(error);
        }
      },
      create: async (token, body) => {
        try {
          const _utilizzi = await _post(token, "utilizzi", body);
          if (_utilizzi) {
            const { retrieve } = get();
            retrieve(token);
          }
        } catch (error) {
          throw new Error(error);
        }
      },
      remove: async (token, id) => {
        try {
          const _utilizzi = await _delete(token, "utilizzi", id);
          if (_utilizzi) {
            const { retrieve } = get();
            retrieve(token);
          }
        } catch (error) {
          throw new Error(error);
        }
      },
    }),
    {
      name: "utilizzi-storage",
      storage: createJSONStorage(() => storage),
    }
  )
);
