const inEuro = (value) => {
    if (isNaN(value)) {
        return "-"
    } else {
        return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(value);
    }
}

const inPct = (value) => {
    if (isNaN(value)) {
        return "-"
    } else {
        return new Intl.NumberFormat('it-IT', { style: 'percent', minimumFractionDigits: 2 }).format(value/100);
    }
}

export {inEuro, inPct};