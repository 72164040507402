import React, { useCallback, useEffect, useMemo, useRef } from "react";
import btnExport from "../assets/btnExport";
import BTNElimina from "../components/BTNElimina";
import PageBody from "../components/PageBody";
import Grigliata from "../components/TableNew";
import { useAziende } from "../stores/useAziende";
import { useUser } from "../stores/useUser";
import { useUtilizzi } from "../stores/useUtilizzi";

const Utilizzi = () => {
  const token = useUser((state) => state.token);
  const utilizzi = useUtilizzi((state) => state.utilizzi);
  const retrieve = useUtilizzi((state) => state.retrieve);
  const create = useUtilizzi((state) => state.create);
  const remove = useUtilizzi((state) => state.remove);
  const aziende = useAziende((state) => state.aziende);

  const columns = [
    { field: "Id" },
    { field: "Anno" },
    { field: "Azienda" },
    { field: "Importo" },
    { field: "Tipologia" },
    { field: "Note" },
    { field: "Azioni", filter: false, sort: false, cellRenderer: BTNElimina },
  ];

  const promptRemove = useCallback(
    (token, id, nome) => {
      if (
        window.confirm(`Sei sicuro di voler eliminare l'utilizzo #${nome}?`)
      ) {
        remove(token, id);
      }
    },
    [remove]
  );

  const data = useMemo(() => {
    try {
      return utilizzi.map((pratica) => {
        return {
          Id: pratica?.id,
          Anno: pratica?.anno,
          Azienda: pratica?.aziende?.name,
          Importo: pratica?.importo
            ? new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(pratica?.importo)
            : "NN",
          Tipologia: pratica?.tipologia,
          Note: pratica?.note,
          Elimina: () => promptRemove(token, pratica.id, pratica.id),
        };
      });
    } catch (error) {
      return [];
    }
  }, [promptRemove, token, utilizzi]);

  useEffect(() => {
    if (token) {
      retrieve(token);
    }
  }, [token, retrieve]);

  const Form = () => {
    const HandleSublit = async (e) => {
      e.preventDefault();

      const _name = name.current.value;
      const _id_azienda = id_azienda.current.value;
      const _anno = anno.current.value;
      const _tipologia = tipologia.current.value;
      const _importo = importo.current.value;
      const _note = note.current.value;

      if (!_name) {
        return;
      }

      const _body = {
        name: _name,
        id_azienda: _id_azienda,
        anno: _anno,
        tipologia: _tipologia,
        importo: _importo,
        note: _note,
      };

      try {
        await create(token, _body);
      } catch (error) {
        window.alert("Errore: " + error);
      }
    };

    const name = React.createRef();
    const id_azienda = React.createRef();
    const anno = React.createRef();
    const tipologia = React.createRef();
    const importo = React.createRef();
    const note = React.createRef();

    return (
      <form method="post" onSubmit={HandleSublit} className="flex-row">
        <p className="py-1">Nome:</p>
        <input
          ref={name}
          type="text"
          placeholder="Nome"
          className="w-full rounded border p-2"
        />
        <p className="py-1">Azienda:</p>
        <select ref={id_azienda} className="w-full rounded border p-2">
          {aziende.map((azienda) => (
            <option key={azienda.id} value={azienda.id}>
              {azienda.name}
            </option>
          ))}
        </select>
        <p className="py-1">Anno:</p>
        <input
          ref={anno}
          type="number"
          placeholder="Anno"
          className="w-full rounded border p-2"
        />
        <p className="py-1">Tipologia:</p>
        <input
          ref={tipologia}
          type="text"
          placeholder="Tipologia"
          className="w-full rounded border p-2"
        />
        <p className="py-1">Importo:</p>
        <input
          ref={importo}
          type="number"
          placeholder="Importo"
          className="w-full rounded border p-2"
        />
        <p className="py-1">Note:</p>
        <input
          ref={note}
          type="text"
          placeholder="Note"
          className="w-full rounded border p-2"
        />

        <button
          type="submit"
          className="bg-futura-scuro mt-6 w-full rounded border p-2 text-white"
        >
          Aggiungi
        </button>
      </form>
    );
  };

  const gridRef = useRef();

  const onBtnExport = useCallback(() => {
    btnExport(gridRef, "Utilizzi", [
      "Id",
      "Anno",
      "Azienda",
      "Importo",
      "Tipologia",
      "Note",
    ]);
  }, []);

  return (
    <PageBody>
      <Grigliata
        data={data}
        form={<Form />}
        gridRef={gridRef}
        name="Utilizzo"
        actionCreate={create}
        onBtnExport={onBtnExport}
        columns={columns}
      />
    </PageBody>
  );
};

export default Utilizzi;
