import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { _delete, _get, _post, _upload } from '../components/fetchers'
import storage from './IDB'

export const useContratti = create(
    persist(
        (set, get) => ({
            contratti: [],
            retrieve: async (token) => {
                try {
                    const _contratti = await _get(token, "contratti")
                    set({ contratti: _contratti })
                } catch (error) {
                    throw new Error(error)
                }
            },
            create: async (token, body) => {
                try {
                    const _contratti = await _post(token, "contratti", body)
                    if (_contratti) {
                        const { retrieve } = get()
                        retrieve(token)
                    }
                } catch (error) {
                    throw new Error(error)
                }
            },
            remove: async (token, id) => {
                try {
                    const _contratti = await _delete(token, "contratti", id)
                    if (_contratti) {
                        const { retrieve } = get()
                        retrieve(token)
                    }
                } catch (error) {
                    throw new Error(error)
                }
            },
            upload: async (token, file) => {
                try {
                    const _contratti = await _upload(token, "contratti/upload", file)
                    return _contratti
                } catch (error) {
                    throw new Error(error)
                }
            }
        }),
        {
            name: 'contratti-storage',
            storage: createJSONStorage(() => storage),
        }
    )
)