import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { _delete, _get, _post } from "../components/fetchers";
import storage from "./IDB";

export const useCommesse = create(
  persist(
    (set, get) => ({
      commesse: [],
      retrieve: async (token) => {
        try {
          const _commesse = await _get(token, "commesse");
          set({ commesse: _commesse });
        } catch (error) {
          throw new Error(error);
        }
      },
      create: async (token, body) => {
        try {
          const _commesse = await _post(token, "commesse", body);
          if (_commesse) {
            const { retrieve } = get();
            retrieve(token);
          }
        } catch (error) {
          throw new Error(error);
        }
      },
      remove: async (token, id) => {
        try {
          const _commesse = await _delete(token, "commesse", id);
          if (_commesse) {
            const { retrieve } = get();
            retrieve(token);
          }
        } catch (error) {
          throw new Error(error);
        }
      },
    }),
    {
      name: "commesse-storage",
      storage: createJSONStorage(() => storage),
    }
  )
);
