import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { _delete, _get, _patch, _post } from "../components/fetchers";
import storage from "./IDB";

export const useAziende = create(
  persist(
    (set, get) => ({
      aziende: [],
      getAz: async (token) => {
        try {
          const aziende = await _get(token, "aziende");
          set({ aziende });
        } catch (error) {
          throw new Error(error);
        }
      },
      postAz: async (token, name) => {
        try {
          const aziende = await _post(token, "aziende", { name });
          if (aziende) {
            const { getAz } = get();
            getAz(token);
            return aziende;
          }
        } catch (error) {
          throw new Error(error);
        }
      },
      delAz: async (token, id) => {
        try {
          const aziende = await _delete(token, "aziende", id);
          if (aziende) {
            const { getAz } = get();
            getAz(token);
          }
        } catch (error) {
          throw new Error(error);
        }
      },
      patch: async (token, id, name) => {
        try {
          const aziende = await _patch(token, "aziende", id, { name });
          if (aziende) {
            const { getAz } = get();
            getAz(token);
          }
        } catch (error) {
          throw new Error(error);
        }
      },
    }),
    {
      name: "aziende-storage",
      storage: createJSONStorage(() => storage),
    }
  )
);
