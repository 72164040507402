import { DocumentDownload } from "iconsax-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { When } from "react-if";
import btnExport from "../assets/btnExport";
import BTNElimina from "../components/BTNElimina";
import PageBody from "../components/PageBody";
import Grigliata from "../components/TableNew";
import { useCategorie } from "../stores/useCategorie";
import { useCommesse } from "../stores/useCommesse";
import { useDocumenti } from "../stores/useDocumenti";
import { usePratiche } from "../stores/usePratiche";
import { useUser } from "../stores/useUser";

const DownloadFile = ({ data }) => (
  <a href={data.File} rel="noreferrer" target="_blank">
    <DocumentDownload color="#0284c7" size={32} />
  </a>
);

const Pratiche = () => {
  const token = useUser((state) => state.token);
  const pratiche = usePratiche((state) => state.pratiche);
  const retrieve = usePratiche((state) => state.retrieve);
  const create = usePratiche((state) => state.create);
  const remove = usePratiche((state) => state.remove);
  const documenti = useDocumenti((state) => state.documenti);
  const commesse = useCommesse((state) => state.commesse);
  const upload = usePratiche((state) => state.upload);

  const columns = [
    { field: "id" },
    { field: "Società" },
    { field: "Commessa" },
    { field: "Tipo Documento" },
    { field: "Documento" },
    { field: "Note" },
    { field: "File", filter: false, sort: false, cellRenderer: DownloadFile },
    { field: "Accettato" },
    { field: "Azioni", filter: false, sort: false, cellRenderer: BTNElimina },
  ];

  const promptRemove = useCallback(
    (token, id, nome) => {
      if (window.confirm(`Sei sicuro di voler eliminare "${nome}"?`)) {
        remove(token, id);
      }
    },
    [remove]
  );

  const data = useMemo(() => {
    try {
      return pratiche.map((pratica) => {
        const _file = `${process.env.REACT_APP_URL}${pratica?.file}`;
        return {
          id: pratica?.id,
          Società: pratica?.commessa?.societa?.nome,
          Commessa: pratica?.commessa?.nome,
          "Tipo Documento": pratica?.documento?.categorie?.nome,
          Documento: pratica?.documento?.nome,
          Note: pratica?.note,
          File: _file,
          Accettato: pratica?.accettato ? true : false,
          Elimina: () => promptRemove(token, pratica.id, pratica.id),
        };
      });
    } catch (error) {
      return [];
    }
  }, [pratiche, promptRemove, token]);

  useEffect(() => {
    if (token) {
      retrieve(token);
    }
  }, [token, retrieve]);

  const Form = () => {
    const HandleSublit = async (e) => {
      e.preventDefault();
      const __documento_id = _documento_id.current.value;
      const __commessa_id = _commessa_id.current.value;
      const __file = _file.current.files;
      const __accettato = _accettato.current.checked;
      const __note = _note.current.value;

      const fIlEs = Array.prototype.slice.call(__file) || [];
      if (__documento_id && __commessa_id && fIlEs.length > 0) {
        fIlEs.forEach(async (f) => {
          try {
            const _uppiti = await upload(token, f);
            const _body = {
              documento_id: __documento_id,
              commessa_id: __commessa_id,
              file: _uppiti.filePath,
              accettato: __accettato,
              note: __note,
            };
            create(token, _body);
          } catch (error) {}
        });
      }
    };

    const categorie = useCategorie((state) => state.categorie);
    const [doctype, setDoc] = useState(categorie[0].id);
    const _documento_id = useRef();
    const _commessa_id = useRef();
    const _file = useRef();
    const _accettato = useRef();
    const _note = useRef();

    return (
      <form method="post" onSubmit={HandleSublit} className="flex-row">
        <p className="py-1">Categoria documento:</p>
        <select
          onChange={(e) => setDoc(e.nativeEvent.target.value)}
          className="w-full rounded border p-2"
        >
          {categorie.map((cat) => (
            <option key={cat.id} value={cat.id}>
              {cat.nome}
            </option>
          ))}
        </select>
        {doctype && (
          <>
            <p className="py-1">Documento:</p>
            <select ref={_documento_id} className="w-full rounded border p-2">
              {documenti
                .filter((doc) => doc.categoria_id === parseInt(doctype))
                .map((doc) => (
                  <option key={doc.id} value={doc.id}>
                    {doc.nome}
                  </option>
                ))}
            </select>
          </>
        )}
        <p className="py-1">File:</p>
        <input
          ref={_file}
          type="file"
          multiple
          className="w-full rounded border p-2"
          placeholder="File"
        />
        <p className="py-1">Commessa:</p>
        <select ref={_commessa_id} className="w-full rounded border p-2">
          {commesse.map((doc) => (
            <option key={doc.id} value={doc.id}>
              {doc.nome}
            </option>
          ))}
        </select>
        <div className="flex flex-row p-2">
          <p className="">Accettato:</p>
          <input
            ref={_accettato}
            type="checkbox"
            className="w-full rounded border p-2"
          />
        </div>
        <p className="py-1">Note:</p>
        <input
          ref={_note}
          type="text"
          className="w-full rounded border p-2"
          placeholder="Note"
        />
        <button
          type="submit"
          className="bg-futura-scuro mt-6 w-full rounded border p-2 text-white"
        >
          Aggiungi
        </button>
      </form>
    );
  };

  const gridRef = useRef();

  const onBtnExport = useCallback(() => {
    btnExport(gridRef, "Pratiche", [
      "Società",
      "Commessa",
      "Tipo Documento",
      "Documento",
      "Note",
      "Accettato",
    ]);
  }, []);

  return (
    <PageBody>
      <When condition={data !== null}>
        <Grigliata
          name="Pratica"
          columns={columns}
          data={data}
          gridRef={gridRef}
          actionCreate={create}
          form={<Form />}
          onBtnExport={onBtnExport}
        />
      </When>
    </PageBody>
  );
};

export default Pratiche;
