import React, { useCallback, useEffect, useMemo, useRef } from "react";
import btnExport from "../assets/btnExport";
import PageBody from "../components/PageBody";
import Grigliata from "../components/TableNew";
import { useCategorie } from "../stores/useCategorie";
import { useUser } from "../stores/useUser";
import BTNElimina from "../components/BTNElimina";

const Categorie = () => {
  const token = useUser((state) => state.token);
  const categorie = useCategorie((state) => state.categorie);
  const retrieve = useCategorie((state) => state.retrieve);
  const create = useCategorie((state) => state.create);
  const remove = useCategorie((state) => state.remove);

  const promptRemove = useCallback(
    (token, id, nome) => {
      if (window.confirm(`Sei sicuro di voler eliminare "${nome}"?`)) {
        remove(token, id);
      }
    },
    [remove]
  );

  const columns = [
    { field: "Ordine" },
    { field: "Categoria" },
    { field: "Informazioni" },
    { field: "Documenti" },
    { field: "Azioni", filter: false, sort: false, cellRenderer: BTNElimina },
  ];

  const data = useMemo(
    () =>
      categorie.map((categorie) => {
        return {
          Id: categorie.id,
          Ordine: categorie.ordine,
          Categoria: categorie.nome,
          Informazioni: categorie.info,
          Documenti: categorie.documenti.map(d=> d.nome).join(", "),
          Elimina: () => promptRemove(token, categorie.id, categorie.nome),
        };
      }),
    [categorie, promptRemove, token]
  );

  useEffect(() => {
    if (token) {
      retrieve(token);
    }
  }, [token, retrieve]);

  const Form = () => {
    const HandleSublit = async (e) => {
      e.preventDefault();
      const _nome = nome.current.value;
      const _info = info.current.value;
      const _ordine = ordine.current.value;
      if (_nome) {
        const _body = {
          nome: _nome,
          info: _info,
          ordine: _ordine,
        };

        try {
          await create(token, _body);
        } catch (error) {
          window.alert("Errore: " + error);
        }
      }
    };

    const nome = useRef();
    const info = useRef();
    const ordine = useRef();

    return (
      <form method="post" onSubmit={HandleSublit} className="flex-row">
        <p className="py-1">Nome:</p>
        <input
          ref={nome}
          type="text"
          placeholder="Nome"
          className="w-full rounded border p-2"
        />
        <p className="py-1">Info:</p>
        <input
          ref={info}
          type="text"
          placeholder="Info"
          className="w-full rounded border p-2"
        />
        <p className="py-1">Ordine:</p>
        <input
          ref={ordine}
          type="number"
          placeholder="Ordine"
          className="w-full rounded border p-2"
        />

        <button
          type="submit"
          className="bg-futura-scuro mt-6 w-full rounded border p-2 text-white"
        >
          Aggiungi
        </button>
      </form>
    );
  };

  const gridRef = useRef();

  const onBtnExport = useCallback(() => {
    btnExport(gridRef, "Categorie Documentali", [
      "Ordine",
      "Categoria",
      "Informazioni",
      "Documenti",
    ]);
  }, []);

  return (
    <PageBody title="Categorie">
      <Grigliata
        data={data}
        form={<Form />}
        gridRef={gridRef}
        name="Documento"
        actionCreate={create}
        onBtnExport={onBtnExport}
        columns={columns}
      />
    </PageBody>
  );
};

export default Categorie;
