import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { _delete, _get, _post, _upload } from "../components/fetchers";
import storage from "./IDB";

export const usePratiche = create(
  persist(
    (set, get) => ({
      pratiche: [],
      retrieve: async (token) => {
        try {
          const _pratiche = await _get(token, "pratiche");
          set({ pratiche: _pratiche });
        } catch (error) {
          throw new Error(error);
        }
      },
      create: async (token, body) => {
        try {
          const _pratiche = await _post(token, "pratiche", body);
          if (_pratiche) {
            const { retrieve } = get();
            retrieve(token);
          }
        } catch (error) {
          throw new Error(error);
        }
      },
      remove: async (token, id) => {
        try {
          const _pratiche = await _delete(token, "pratiche", id);
          if (_pratiche) {
            const { retrieve } = get();
            retrieve(token);
          }
        } catch (error) {
          throw new Error(error);
        }
      },
      upload: async (token, file) => {
        try {
          const up = await _upload(token, "pratiche/upload", file);
          if (up) {
            return up;
          } else {
            throw new Error();
          }
        } catch (error) {
          throw new Error(error);
        }
      },
    }),
    {
      name: "pratiche-storage",
      storage: createJSONStorage(() => storage),
    }
  )
);
