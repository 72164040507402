import { CloseCircle } from "iconsax-react";
import React, { useState } from "react";

const Modal = ({ children = {}, title = undefined, ok = () => { }, onOpen = () => { } }) => {

    const [active, toggle] = useState(false);

    const handleClose = () => {
        toggle(false);
    }

    const handleOk = () => {
        if (ok) ok();
        handleClose();
    }

    const handleOpen = () => {
        if (onOpen) onOpen();
        toggle(true);
    }

    return (
        <>
            <label htmlFor={title} className='rounded bg-futura-blu px-4 py-2 cursor-pointer text-futura-bianco text-base' onClick={handleOpen}>{title}</label>
            <dialog className="absolute w-full h-full p-0 m-0 top-0 bg-futura-bgModal/50 z-50" open={active}>
                <div className="top-0 flex w-full h-full justify-center items-center">
                    <div className="bg-futura-bianco flex rounded p-4 flex-col">
                        <div className="flex flex-row justify-between items-center">
                            <strong>{title}</strong>
                            <CloseCircle className="cursor-pointer" size="25" color="#0284c7" onClick={handleClose} />
                        </div>
                        <div className="flex flex-1 mt-2 flex-col">
                            {children}
                            {/*<button className="rounded bg-futura-blu px-4 py-2 cursor-pointer text-futura-bianco text-base" onClick={handleOk}>Salva</button>*/}
                        </div>
                    </div>
                </div>
            </dialog>
        </>
    );
};

export default Modal;