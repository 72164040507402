import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../components/SideBar";

const HomePage = () => {

    return (
        <div className="flex flex-1 flex-row h-full">
            <SideBar />
            <Outlet />
        </div>
    );
};

export default HomePage;