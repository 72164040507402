import { DocumentDownload } from "iconsax-react";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import btnExport from "../assets/btnExport";
import BTNElimina from "../components/BTNElimina";
import PageBody from "../components/PageBody";
import Grigliata from "../components/TableNew";
import { useAziende } from "../stores/useAziende";
import { useContratti } from "../stores/useContratti";
import { useSocieta } from "../stores/useSocieta";
import { useUser } from "../stores/useUser";

const Contratti = () => {
  const token = useUser((state) => state.token);
  const societa = useSocieta((state) => state.societa);
  const aziende = useAziende((state) => state.aziende);
  const contratti = useContratti((state) => state.contratti);
  const retrieve = useContratti((state) => state.retrieve);
  const create = useContratti((state) => state.create);
  const remove = useContratti((state) => state.remove);
  const upload = useContratti((state) => state.upload);

  const promptRemove = useCallback((token, id, az, soc) => {
    if (
      window.confirm(
        `Sei sicuro di voler eliminare il documento tra ${az} e ${soc}?`
      )
    ) {
      remove(token, id);
    }
  }, []);

  const DownloadFile = ({ data }) => (
    <a href={data.Documento} rel="noreferrer" target="_blank">
      <DocumentDownload color="#0284c7" size={32} />
    </a>
  );

  const columns = [
    { field: "Id", hide: true },
    { field: "Azienda" },
    { field: "Società" },
    {
      field: "Documento",
      filter: false,
      sort: false,
      cellRenderer: DownloadFile,
    },
    { field: "Note" },
    { field: "Azioni", filter: false, sort: false, cellRenderer: BTNElimina },
  ];

  const data = useMemo(() => {
    try {
      return contratti.map((contratto) => {
        const _azienda =
          aziende.find((azienda) => azienda.id === contratto.societa.aziende_id)
            .name || "N/A";
        return {
          Id: contratto.id,
          Azienda: _azienda,
          Società: contratto.societa.nome,
          Documento: `${process.env.REACT_APP_URL}${contratto?.file}`,
          Note: contratto.note,
          Elimina: () =>
            promptRemove(token, contratto.id, _azienda, contratto.societa.nome),
        };
      });
    } catch (error) {
      return [];
    }
  }, [contratti, aziende, promptRemove, token]);

  useEffect(() => {
    if (token) {
      retrieve(token);
    }
  }, [token, retrieve]);

  const Form = () => {
    const HandleSublit = (e) => {
      e.preventDefault();
      const _az = az.current.value;
      const _file = file.current.files;
      const _note = note.current.value;
      const fIlEs = Array.prototype.slice.call(_file) || [];
      if (_az && fIlEs.length > 0) {
        fIlEs.forEach(async (f) => {
          try {
            const _uppiti = await upload(token, f);
            const _body = {
              societa_id: _az,
              file: _uppiti.filePath,
              note: _note,
            };
            create(token, _body);
          } catch (error) {}
        });
      }
    };

    const az = useRef();
    const file = useRef();
    const note = useRef();

    return (
      <form method="post" onSubmit={HandleSublit} className="flex-row">
        <p className="py-1">Nome azienda cessionaria:</p>
        <select ref={az} className="w-full rounded border p-2">
          {societa.map((societa) => {
            return (
              <option key={societa.id} value={societa.id}>
                ({societa.aziende.name}) {societa.nome}
              </option>
            );
          })}
        </select>
        <p className="py-1">File:</p>
        <input
          ref={file}
          type="file"
          placeholder="File"
          className="w-full rounded border p-2"
          multiple
        />
        <p className="py-1">Note:</p>
        <input
          ref={note}
          type="text"
          placeholder="Note"
          className="w-full rounded border p-2"
        />
        <button
          type="submit"
          className="bg-futura-scuro mt-6 w-full rounded border p-2 text-white"
        >
          Aggiungi
        </button>
      </form>
    );
  };

  const gridRef = useRef();

  const onBtnExport = useCallback(() => {
    btnExport(gridRef, "Contratti", [
      "Azienda",
      "Società",
      "Documento",
      "Note",
    ]);
  }, []);

  return (
    <PageBody>
      <Grigliata
        data={data}
        form={<Form />}
        gridRef={gridRef}
        name="Contratto"
        actionCreate={create}
        onBtnExport={onBtnExport}
        columns={columns}
      />
    </PageBody>
  );
};

export default Contratti;
