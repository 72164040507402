import React, { memo } from 'react';
import { ArrowDown2 } from 'iconsax-react';
import { If, Then, Else } from 'react-if';

const DropDown = ({ options, selected, title, onChange }) => {

    const details = React.useRef();

    const handleClick = (value) => {
        if (details.current) {
            details.current.removeAttribute('open');
        }
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div>
            <details ref={details}>
                <summary id="dropDown" className="flex flex-row gap-3 rounded bg-futura-blu px-4 py-2 cursor-pointer text-futura-bianco items-center">
                    <If condition={selected}>
                        <Then>
                            <span className='text-base'>{selected?.label}</span>
                        </Then>
                        <Else>
                            <span className='text-base'>{options?.at(0)?.label}</span>
                        </Else>
                    </If>
                    <ArrowDown2 size="18" color="#f4f4f5" />
                </summary>
                <div className="absolute mt-1 w-56">
                    <div className="bg-futura-blu rounded flex flex-col gap-2 p-1">
                        {options.map((option) =>
                            <span
                                className="px-2 py-1 cursor-pointer text-futura-bianco hover:bg-futura-scuro rounded"
                                onClick={() => handleClick(option)}
                                key={option.value}>
                                {option.label}
                            </span>
                        )}
                    </div>
                </div>
            </details>
        </div>
    )
};

export default memo(DropDown);