import React, { memo } from "react";

const DataBox = ({ title, subtitle, children }) => (
    <div className='flex flex-1 flex-col border border-futura-bordoTab mb-1'>
        <h1 className="font-bold text-center m-2 mb-0 leading-tight">{title}</h1>
        <h2 className="font-light text-center m-2 mt-0 leading-tight">{subtitle}</h2>
        <div className='flex flex-1 flex-col border border-futura-bordoTab m-2'>
            {children}
        </div>
    </div>
);

export default memo(DataBox);