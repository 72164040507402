import React, { useMemo } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import logo from "../assets/logo-bitone.svg";
import { useUser } from "../stores/useUser";
import { Bag, Building, Category2, DocumentText, Filter, FolderConnection, LogoutCurve, Money, MoneyArchive, PenAdd, StatusUp } from "iconsax-react";

const CustomNavLink = ({ to, children }) => {

    const match = useMatch(to);
    const navigate = useNavigate();

    const style = useMemo(() => {
        const base = "flex-1 flex items-center gap-2 text-white my-1 p-2 rounded cursor-pointer opacity-90 hover:opacity-100";
        if (match?.pathname === to) {
            return base + " font-bold bg-futura-azzurro opacity-100";
        } else {
            return base + " bg-futura-blu opacity-90";
        }
    }, [match]);

    const handleClick = () => {
        if (to !== null) {
            navigate(to);
        }
    };

    return (
        <li onClick={handleClick} className={style}>
            {children}
        </li>
    );
};


const SideBar = () => {

    const logout = useUser(state => state.logout);
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate("/login");
    };

    const Divider = () => <hr className="border-futura-blu my-2 opacity-25" />;

    return (
        <aside className="p-2 w-64 flex flex-col justify-between">
            <ul className="flex flex-col">
                <li onClick={() => navigate("/app/dashboard")} className="flex-1 flex items-center gap-2 text-white my-2 p-2 rounded cursor-pointer">
                    <img src={logo} alt="logo" />
                    FuturaCare crediti
                </li>
                <CustomNavLink to={"/app/dashboard"}><StatusUp size="20" color="#fff" variant="Bulk" /> Dashboard</CustomNavLink>
                <CustomNavLink to={"/app/esplora"}><FolderConnection size="20" color="#fff" variant="Bulk" /> Esplora</CustomNavLink>
                <Divider />
                <CustomNavLink to={"/app/aziende"}><Building size="20" color="#fff" variant="Bulk" /> Aziende Cessionarie</CustomNavLink>
                <CustomNavLink to={"/app/societa"}><Bag size="20" color="#fff" variant="Bulk" /> Società Cedenti</CustomNavLink>
                <CustomNavLink to={"/app/contratti"}><PenAdd size="20" color="#fff" variant="Bulk" /> Contratti</CustomNavLink>
                <Divider />
                <CustomNavLink to={"/app/commesse"}><Money size="20" color="#fff" variant="Bulk" /> Commesse</CustomNavLink>
                <CustomNavLink to={"/app/pratiche"}><Filter size="20" color="#fff" variant="Bulk" /> Pratiche</CustomNavLink>
                <CustomNavLink to={"/app/utilizzi"}><MoneyArchive size="20" color="#fff" variant="Bulk" /> Utilizzi</CustomNavLink>
                <Divider />
                <CustomNavLink to={"/app/categorie"}><Category2 size="20" color="#fff" variant="Bulk" /> Categorie documentali</CustomNavLink>
                <CustomNavLink to={"/app/documenti"}><DocumentText size="20" color="#fff" variant="Bulk" /> Tipologia Documenti</CustomNavLink>
            </ul>
            <ul>
                <li onClick={() => window.open("https://futura4me.com", '_blank')} className="flex-1 flex items-center gap-2 text-white my-1 p-2 cursor-pointer">© 2023 FuturaCare s.r.l.</li>
                <li onClick={handleLogout} className="flex-1 flex items-center gap-2 text-white bg-futura-blu my-1 p-2 rounded cursor-pointer">
                    <LogoutCurve size="20" color="#fff" variant="Bulk" /> Esci
                </li>
            </ul>
        </aside>
    );
};

export default SideBar;