import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { _delete, _get, _post } from "../components/fetchers";
import storage from "./IDB";

export const useSocieta = create(
  persist(
    (set, get) => ({
      societa: [],
      retrieve: async (token) => {
        try {
          const _societa = await _get(token, "societa");
          set({ societa: _societa });
        } catch (error) {
          throw new Error(error);
        }
      },
      create: async (token, body) => {
        try {
          const _societa = await _post(token, "societa", body);
          if (_societa) {
            const { retrieve } = get();
            retrieve(token);
          }
        } catch (error) {
          throw new Error(error);
        }
      },
      remove: async (token, id) => {
        try {
          const _societa = await _delete(token, "societa", id);
          if (_societa) {
            const { retrieve } = get();
            retrieve(token);
          }
        } catch (error) {
          throw new Error(error);
        }
      },
    }),
    {
      name: "societa-storage",
      storage: createJSONStorage(() => storage),
    }
  )
);
