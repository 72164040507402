import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import storage from "./IDB";

const login = async (mail, pswd) => {
  const response = await fetch(`${process.env.REACT_APP_URL}/utenti/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: mail,
      password: pswd,
    }),
  });
  try {
    const data = await response.json();
    if (data.token) {
      return data.token;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const useUser = create(
  persist(
    (set, get) => ({
      token: null,
      logout: () => set({ token: null }),
      login: async (mail, pswd, callback) => {
        try {
          const token = await login(mail, pswd);
          set({ token });
          return token;
        } catch (error) {
          throw new Error(error);
        }
      },
    }),
    {
      name: "token-storage",
      storage: createJSONStorage(() => storage),
    }
  )
);
