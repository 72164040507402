import { ArrowCircleDown2, ArrowCircleUp2, Trash } from "iconsax-react";
import React, { memo, useEffect, useMemo, useRef } from "react";
import { Else, If, Then } from "react-if";
import DataBox from "../components/DataBox";
import DataRow from "../components/DataRow";
import DropDown from "../components/DropDown";
import Modal from "../components/Modal";
import PageBody from "../components/PageBody";
import YSelect from "../components/YSelect";
import { inEuro, inPct } from "../components/inEuro";
import { useAziende } from "../stores/useAziende";
import { useCategorie } from "../stores/useCategorie";
import { useCommesse } from "../stores/useCommesse";
import useDashboard from "../stores/useDashboard";
import { useDocumenti } from "../stores/useDocumenti";
import { usePratiche } from "../stores/usePratiche";
import { useSocieta } from "../stores/useSocieta";
import { useUser } from "../stores/useUser";
import { useUtilizzi } from "../stores/useUtilizzi";



const DashBoard = () => {

    const aziende = useAziende((state) => state.aziende);
    const token = useUser((state) => state.token);
    const utilizzi = useUtilizzi((state) => state.utilizzi);

    const year = useDashboard((state) => state.year);
    const f24 = useDashboard((state) => state.f24);
    const setF24 = useDashboard((state) => state.setF24);
    const fatturato = useDashboard((state) => state.fatturato);
    const setFatturato = useDashboard((state) => state.setFatturato);
    const percentAbbatimento = useDashboard((state) => state.percentAbbatimento);
    const setPercentAbbatimento = useDashboard((state) => state.setPercentAbbatimento);
    const syncUp = useDashboard((state) => state.syncUp);
    const syncDown = useDashboard((state) => state.syncDown);
    const commesse = useCommesse((state) => state.commesse);

    const getAz = useAziende((state) => state.getAz);
    const getCategorie = useCategorie((state) => state.retrieve);
    const getCommesse = useCommesse((state) => state.retrieve);
    const getDocumenti = useDocumenti((state) => state.retrieve);
    const getPratiche = usePratiche((state) => state.retrieve);
    const getSocieta = useSocieta((state) => state.retrieve);
    const getUtilizzi = useUtilizzi((state) => state.retrieve);

    useEffect(() => {
        getAz(token);
        getCategorie(token);
        getCommesse(token);
        getDocumenti(token);
        getPratiche(token);
        getSocieta(token);
        getUtilizzi(token);
    }, [getAz, getCategorie, getCommesse, getDocumenti, getPratiche, getSocieta, getUtilizzi, token]);

    const options = useMemo(() => {
        const az = [{ value: null, label: "Tutte le aziende" }, ...aziende.map((az) => ({ value: az.id, label: az.name }))]
        return az;
    }, [aziende]);

    const [selected, setSelected] = React.useState(options[0]);

    useEffect(() => {
        setSelected(options[0]);
    }, [options]);

    const valf24 = useMemo(() => {
        if (selected.label === "Tutte le aziende") {
            return Object.values(f24).reduce((acc, val) => acc + (parseFloat(val[year]) ?? 0), 0);
        }
        return f24?.[selected.label]?.[year] ?? 0;
    }, [f24?.[selected.label]?.[year], selected, year]);

    const valfatturato = useMemo(() => {
        if (selected.label === "Tutte le aziende") {
            return Object.values(fatturato).reduce((acc, val) => acc + (parseInt(val[year]) ?? 0), 0);
        }
        return fatturato?.[selected.label]?.[year] ?? 0;
    }, [fatturato?.[selected.label]?.[year], selected, year]);

    const valpercentAbbatimento = useMemo(() => {
        if (selected.label === "Tutte le aziende") {
            const numMedia = Object.values(percentAbbatimento).filter(v => v[year] && v[year] !== 0).length;
            return Object.values(percentAbbatimento).reduce((acc, val) => acc + (parseInt(val[year]) ?? 0), 0) / numMedia;
        }
        return percentAbbatimento?.[selected.label]?.[year] ?? 0;
    }, [percentAbbatimento?.[selected.label]?.[year], selected, year]);

    const _f24 = useRef();
    const _fatturato = useRef();
    const _percentAbbatimento = useRef();

    const saveData = () => {
        try {
            setF24(selected.label, year, _f24.current.value);
            setFatturato(selected.label, year, _fatturato.current.value);
            setPercentAbbatimento(selected.label, year, _percentAbbatimento.current.value);
        } catch (error) {
        }
    }

    const openForm = () => {
        _f24.current.value = valf24;
        _fatturato.current.value = valfatturato;
        _percentAbbatimento.current.value = valpercentAbbatimento;
    }

    const acquistato = useMemo(() => {
        const filtra_commesse = commesse.filter((commessa) => {
            if (selected.label === "Tutte le aziende") {
                return true;
            }
            return commessa.societa.aziende_id === selected.value;
        });
        const _out = filtra_commesse.map((commessa) => {
            const annualita = [...Array(commessa.quanti_anni).keys()].map(piu => commessa.annualita_inizio + piu)
            //const importo_scontato = (commessa.importo * (commessa.sconto / 100)).toFixed(2)
            const importo_annuale = (commessa.importo / commessa.quanti_anni).toFixed(2)
            if (annualita.includes(year)) {
                return importo_annuale;
            }
            return 0;
        }).reduce((acc, val) => acc + (parseFloat(val) ?? 0), 0);
        return _out
    }, [commesse, selected, year]);

    const sconto_ottenuto_per_anno_in_eur = useMemo(() => {
        const filtra_commesse = commesse.filter((commessa) => {
            if (selected.label === "Tutte le aziende") {
                return true;
            }
            return commessa.societa.aziende_id === selected.value;
        });
        const _out = filtra_commesse.map((commessa) => {
            const annualita = [...Array(commessa.quanti_anni).keys()].map(piu => commessa.annualita_inizio + piu)
            const importo_scontato = (commessa.importo * (commessa.sconto / 100)).toFixed(2)
            if (annualita.includes(year)) {
                return (importo_scontato / commessa.quanti_anni);
            }
            return 0;
        }).reduce((acc, val) => acc + (parseFloat(val) ?? 0), 0);
        return _out
    }, [commesse, selected, year]);

    const data = useMemo(() => {
        const _perc_abb_real = valpercentAbbatimento / 100;
        const _tot_acq = valf24 * _perc_abb_real;
        const _perc_car_fis = valf24 / valfatturato * 100;
        const _perc_car_fis_real = _perc_car_fis / 100
        const _car_f24 = valfatturato * (_perc_car_fis_real)

        return {
            perc_decr_car_fisc: acquistato / valf24 * 100,
            tot_da_acq: _tot_acq,
            es_da_procacc: _tot_acq - acquistato,
            perc_car_fisc: _perc_car_fis,
            carico_f24: _car_f24,
            netto_car_fis: _car_f24 - acquistato,
            perc_nett_carico_fis: (100 * (_car_f24 - acquistato) / valfatturato),
        }

    }, [valf24, valfatturato, valpercentAbbatimento, acquistato]);

    const azzera = () => {
        if (window.confirm("Sei sicuro di voler azzerare i dati?\nLa procedura è irreversibile e dovrai reinserirli a mano.")) {
            options.forEach((azienda) => {
                setF24(azienda.label, year, 0);
                setFatturato(azienda.label, year, 0);
                setPercentAbbatimento(azienda.label, year, 0);
            })
        }
    }

    const utilizzi_totali_anno = useMemo(() => {
        const filtra_utilizzi = utilizzi.filter((utilizzo) => {
            if (selected.label === "Tutte le aziende") {
                return true;
            }
            return utilizzo.aziende.id === selected.value;
        });
        const _out1 = filtra_utilizzi.map((utilizzo) => {
            if (parseInt(utilizzo.anno) === year) {
                return utilizzo.importo;
            }
            return 0;
        })
        const _out = _out1.reduce((acc, val) => acc + (parseFloat(val) ?? 0), 0);
        return _out
    }, [utilizzi, selected, year]);


    return (
        <PageBody>
            <div className='flex flex-row justify-between mb-3'>
                <div className="flex flex-row gap-1">
                    <DropDown options={options} selected={selected} onChange={(value) => setSelected(value)} />
                    <YSelect />
                </div>
                <div className="flex flex-row justify-end gap-1">
                    <div onClick={() => syncUp(token)} className="flex rounded bg-futura-blu px-4 py-2 cursor-pointer text-futura-bianco items-center justify-center">
                        <ArrowCircleUp2 className="inline-block" size={20} />
                    </div>
                    <div onClick={() => syncDown(token)} className="flex rounded bg-futura-blu px-4 py-2 cursor-pointer text-futura-bianco items-center justify-center">
                        <ArrowCircleDown2 className="inline-block" size={20} />
                    </div>
                    <If condition={selected !== undefined && selected !== options[0]}>
                        <Then>
                            <Modal title="Personalizza parametri" onOpen={openForm}>
                                <form className="m-4 flex flex-col">
                                    <span className="font-light">Azienda: {selected?.label} ({year})</span>
                                    <span className="mt-3 mb-1">Totale fatturato:</span>
                                    <label className="font-bold font-mono">€
                                        <input
                                            ref={_fatturato}
                                            type="number"
                                            step="0.01"
                                            defaultValue={valfatturato}
                                            placeholder="Totale fatturato"
                                            className='font-normal ml-2 border border-futura-bordoBtn rounded px-2 py-1'
                                        />
                                    </label>
                                    <span className="mt-3 mb-1">Totale F/24:</span>
                                    <label className="font-bold font-mono">€
                                        <input
                                            ref={_f24}
                                            type="number"
                                            step="0.01"
                                            defaultValue={valf24}
                                            placeholder="Totale F/24"
                                            className='font-normal ml-2 border border-futura-bordoBtn rounded px-2 py-1'
                                        />
                                    </label>
                                    <span className="mt-3 mb-1">% di abbattimento desiderata:</span>
                                    <label className="font-bold font-mono">%
                                        <input
                                            ref={_percentAbbatimento}
                                            type="number"
                                            step="0.01"
                                            defaultValue={valpercentAbbatimento}
                                            placeholder="% di abbattimento desiderata"
                                            className='font-normal ml-2 border border-futura-bordoBtn rounded px-2 py-1'
                                        />
                                    </label>
                                    <button
                                        type="button"
                                        onClick={saveData}
                                        className="mt-3 bg-futura-blu text-white font-bold py-2 px-4 rounded"
                                    >
                                        Salva
                                    </button>
                                </form>
                            </Modal>
                        </Then>
                        <Else>
                            <button onClick={azzera} className="hidden items-center gap-2 flex-row rounded bg-red-500 px-4 py-2 cursor-pointer text-futura-bianco text-base"><Trash size="18" variant="Bold" /> Azzera i dati</button>
                        </Else>
                    </If>
                </div>
            </div>
            <div className='grid grid-cols-2 gap-2'>
                <DataBox title="Pianificazione finanziaria" subtitle={`${selected.label} - ${year}`}>
                    <DataRow label="Totale fatturato" value={inEuro(valfatturato)} />
                    <DataRow label="Percentuale carico fiscale" value={inPct(data.perc_car_fisc)} />
                    <DataRow label="Totale crediti acquistati" value={inEuro(acquistato)} />
                    <DataRow label="Sconto ottenuto" value={inEuro(sconto_ottenuto_per_anno_in_eur)} />
                    <DataRow label="Netto da pagare" value={inEuro(acquistato - sconto_ottenuto_per_anno_in_eur)} />
                    {/*<DataRow label="Netto carico fiscale" value={inEuro(data.netto_car_fis)} />
                    <DataRow label="Percentuale netto carico fiscale" value={inPct(data.perc_nett_carico_fis)} />*/}
                </DataBox>
                <DataBox title="Decremento carico fiscale annuo" subtitle={`${selected.label} - ${year}`}>
                    <DataRow label="Totale F/24 annuo" value={inEuro(valf24)} />
                    <DataRow label="% di decremento degli F/24" value={inPct(sconto_ottenuto_per_anno_in_eur / valf24 * 100)} />
                    <DataRow label="Sconto annuo accumulato" value={inEuro(sconto_ottenuto_per_anno_in_eur)} />
                    <DataRow label="Netto carico fiscale post sconto" value={inEuro(valf24 - sconto_ottenuto_per_anno_in_eur)} />
                    <DataRow label="Percentuale di decremento del carico fiscale" value={inPct((valf24 - sconto_ottenuto_per_anno_in_eur) / valfatturato * 100)} />
                </DataBox>
                <DataBox title="Utilizzo F/24" subtitle={`${selected.label} - ${year}`}>
                    <DataRow label="Credito disponibile per anno" value={inEuro(acquistato)} />
                    <DataRow label="Utilizzi effettivi caricati" value={inEuro(utilizzi_totali_anno)} />
                    <DataRow label="Netto disponibile" value={inEuro(acquistato - utilizzi_totali_anno)} />
                </DataBox>
                <DataBox title="Abbattimento carico fiscale" subtitle={`${selected.label} - ${year}`}>
                    <DataRow label="Percentuale desiderata" value={`${valpercentAbbatimento}%`} />
                    <DataRow label="Totale da acquistare" value={inEuro(data.tot_da_acq)} />
                    <DataRow label="Esigenza da procacciare" value={inEuro(data.es_da_procacc)} />
                </DataBox>
            </div>
        </PageBody>
    );
}

export default memo(DashBoard);