import { utils, writeFile } from "xlsx";

const btnExport = (gridRef, name, columnKeys, customHeader) => {
    const date = new Date();
    const fileName = `${name.replace(" ", "-")}-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}.xlsx`;
    const _csv = gridRef.current.api.getDataAsCsv({
        fileName: fileName,
        columnSeparator: ';',
        suppressQuotes: true,
        prependContent: customHeader ?? `${name} al ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}\n`,
        columnKeys: columnKeys,

    });
    const workbook = utils.book_new();
    const ws = utils.aoa_to_sheet(_csv.split('\n').map(_ => _.split(';').map(_ => _.trim())));
    utils.book_append_sheet(workbook, ws, name);
    writeFile(workbook, fileName);
}

export default btnExport;
