const AG_GRID_LOCALE_IT = {
    // Set Filter
    selectAll: '(Seleziona tutto)',
    selectAllSearchResults: '(Seleziona tutti i risultati della ricerca)',
    addCurrentSelectionToFilter: 'Aggiungi la selezione corrente al filtro',
    searchOoo: 'Cerca...',
    blanks: '(Vuoto)',
    noMatches: 'Nessuna corrispondenza',

    // Number Filter & Text Filter
    filterOoo: 'Filtra...',
    equals: 'Uguale a',
    notEqual: 'Non è uguale a',
    blank: 'Vuoto',
    notBlank: 'Non vuoto',
    empty: 'Scegli uno',

    // Number Filter
    lessThan: 'Minore di',
    greaterThan: 'Maggiore di',
    lessThanOrEqual: 'Minore o uguale a',
    greaterThanOrEqual: 'Maggiore o uguale a',
    inRange: 'Fra',
    inRangeStart: 'Da',
    inRangeEnd: 'A',

    // Text Filter
    contains: 'Contiene',
    notContains: 'Non contiene',
    startsWith: 'Inizia con',
    endsWith: 'Finisce con',

    // Date Filter
    dateFormatOoo: 'dd-mm-yyyy',
    before: 'Prima di',
    after: 'Dopo',

    // Filter Conditions
    andCondition: 'E',
    orCondition: 'O',

    // Filter Buttons
    applyFilter: 'Applica',
    resetFilter: 'Reimposta',
    clearFilter: 'Annulla',
    cancelFilter: 'Annulla',

    // Filter Titles
    textFilter: 'Filtro di testo',
    numberFilter: 'Filtro numerico',
    dateFilter: 'Filtro data',
    setFilter: 'Imposta filtro',

    // Group Column Filter
    groupFilterSelect: 'Seleziona campo:',

    // Advanced Filter
    advancedFilterContains: 'contiene',
    advancedFilterNotContains: 'non contiene',
    advancedFilterTextEquals: 'uguale a',
    advancedFilterTextNotEqual: 'non uguale a',
    advancedFilterStartsWith: 'inizia con',
    advancedFilterEndsWith: 'finisce con',
    advancedFilterBlank: 'è vuoto',
    advancedFilterNotBlank: 'non è vuoto',
    advancedFilterEquals: '=',
    advancedFilterNotEqual: '≠',
    advancedFilterGreaterThan: '>',
    advancedFilterGreaterThanOrEqual: '≥',
    advancedFilterLessThan: '<',
    advancedFilterLessThanOrEqual: '≤',
    advancedFilterTrue: 'è vero',
    advancedFilterFalse: 'è falso',
    advancedFilterAnd: 'E',
    advancedFilterOr: 'O',
    advancedFilterApply: 'Applica',
    advancedFilterBuilder: 'Costruttore',
    advancedFilterValidationMissingColumn: 'Manca la colonna',
    advancedFilterValidationMissingOption: 'Manca l\'opzione',
    advancedFilterValidationMissingValue: 'Manca il valore',
    advancedFilterValidationInvalidColumn: 'Colonna non trovata',
    advancedFilterValidationInvalidOption: 'Opzione non trovata',
    advancedFilterValidationMissingQuote: 'Il valore manca una chiusura virgolette',
    advancedFilterValidationNotANumber: 'Il valore non è un numero',
    advancedFilterValidationInvalidDate: 'Il valore non è una data valida',
    advancedFilterValidationMissingCondition: 'Manca la condizione',
    advancedFilterValidationJoinOperatorMismatch: 'Gli operatori di join all\'interno di una condizione devono essere gli stessi',
    advancedFilterValidationInvalidJoinOperator: 'Operatore di join non trovato',
    advancedFilterValidationMissingEndBracket: 'Manca la parentesi di chiusura',
    advancedFilterValidationExtraEndBracket: 'Troppe parentesi di chiusura',
    advancedFilterValidationMessage: 'L\'espressione contiene un errore. ${variable} - ${variable}.',
    advancedFilterValidationMessageAtEnd: 'L\'espressione contiene un errore. ${variable} alla fine dell\'espressione.',
    advancedFilterBuilderTitle: 'Filtro avanzato',
    advancedFilterBuilderApply: 'Applica',
    advancedFilterBuilderCancel: 'Annulla',
    advancedFilterBuilderAddButtonTooltip: 'Aggiungi filtro o gruppo',
    advancedFilterBuilderRemoveButtonTooltip: 'Rimuovi',
    advancedFilterBuilderMoveUpButtonTooltip: 'Sposta su',
    advancedFilterBuilderMoveDownButtonTooltip: 'Sposta giù',
    advancedFilterBuilderAddJoin: 'Aggiungi gruppo',
    advancedFilterBuilderAddCondition: 'Aggiungi filtro',
    advancedFilterBuilderSelectColumn: 'Seleziona una colonna',
    advancedFilterBuilderSelectOption: 'Seleziona un\'opzione',
    advancedFilterBuilderEnterValue: 'Inserisci un valore...',
    advancedFilterBuilderValidationAlreadyApplied: 'Il filtro corrente è già applicato.',
    advancedFilterBuilderValidationIncomplete: 'Non tutte le condizioni sono complete.',
    advancedFilterBuilderValidationSelectColumn: 'Devi selezionare una colonna.',
    advancedFilterBuilderValidationSelectOption: 'Devi selezionare un\'opzione.',
    advancedFilterBuilderValidationEnterValue: 'Devi inserire un valore.',

    // Side Bar
    columns: 'Colonne',
    filters: 'Filtri',

    // columns tool panel,
    pivotMode: 'Modalità Pivot',
    groups: 'Gruppi di riga',
    rowGroupColumnsEmptyMessage: 'Trascina qui per impostare i gruppi di riga',
    values: 'Valori',
    valueColumnsEmptyMessage: 'Trascina qui per aggregare',
    pivots: 'Etichette delle colonne',
    pivotColumnsEmptyMessage: 'Trascina qui per impostare le etichette delle colonne',

    // Header of the Default Group Column
    group: 'Gruppo',

    // Row Drag
    rowDragRow: 'riga',
    rowDragRows: 'righe',

    // Other
    loadingOoo: 'Caricamento in corso...',
    loadingError: 'ERR',
    noRowsToShow: 'Nessuna riga da visualizzare',
    enabled: 'Abilitato',

    // Menu
    pinColumn: 'Fissa colonna',
    pinLeft: 'Fissa a sinistra',
    pinRight: 'Fissa a destra',
    noPin: 'Non fissare',
    valueAggregation: 'Agregazione valore',
    noAggregation: 'Nessuno',
    autosizeThiscolumn: 'Autodimensiona questa colonna',
    autosizeAllColumns: 'Autodimensiona tutte le colonne',
    groupBy: 'Raggruppa per',
    ungroupBy: 'Rimuovi raggruppamento per',
    ungroupAll: 'Rimuovi tutti i raggruppamenti',
    addToValues: 'Aggiungi ${variable} ai valori',
    removeFromValues: 'Rimuovi ${variable} dai valori',
    addToLabels: 'Aggiungi ${variable} alle etichette',
    removeFromLabels: 'Rimuovi ${variable} dalle etichette',
    resetColumns: 'Ripristina colonne',
    expandAll: 'Espandi tutti i gruppi di riga',
    collapseAll: 'Chiudi tutti i gruppi di riga',
    copy: 'Copia',
    ctrlC: 'Ctrl+C',
    ctrlX: 'Ctrl+X',
    copyWithHeaders: 'Copia con intestazioni',
    copyWithGroupHeaders: 'Copia con intestazioni del gruppo',
    cut: 'Taglia',
    paste: 'Incolla',
    ctrlV: 'Ctrl+V',
    export: 'Esporta',
    csvExport: 'Esportazione CSV',
    excelExport: 'Esportazione Excel',

    // Enterprise Menu Aggregation and Status Bar,
    sum: 'Somma',
    first: 'Primo',
    last: 'Ultimo',
    min: 'Minimo',
    max: 'Massimo',
    none: 'Nessuno',
    count: 'Conteggio',
    avg: 'Media',
    filteredRows: 'Filtrati',
    selectedRows: 'Selezionati',
    totalRows: 'Totale righe',
    totalAndFilteredRows: 'Righe',
    more: 'Altro',
    to: 'a',
    of: 'di',
    page: 'Pagina',
    pageLastRowUnknown: '?',
    nextPage: 'Pagina successiva',
    lastPage: 'Ultima pagina',
    firstPage: 'Prima pagina',
    previousPage: 'Pagina precedente',
    pageSizeSelectorLabel: 'Dimensione pagina:',

    // Pivoting
    pivotColumnGroupTotals: 'Totale',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Grafico pivot e modalità pivot',
    pivotChart: 'Grafico pivot',
    chartRange: 'Intervallo grafico',
    columnChart: 'Grafico a colonne',
    groupedColumn: 'Raggruppato',
    stackedColumn: 'Impilato',
    normalizedColumn: 'Impalato al 100%',
    barChart: 'Grafico a barre',
    groupedBar: 'Raggruppato',
    stackedBar: 'Impilato',
    normalizedBar: 'Impalato al 100%',
    pieChart: 'Grafico a torta',
    pie: 'Torta',
    doughnut: 'Ciambella',
    line: 'Grafico lineare',
    xyChart: 'Grafico XY (disperso)',
    scatter: 'Disperso',
    bubble: 'Bubble',
    areaChart: 'Grafico ad area',
    area: 'Area',
    stackedArea: 'Impilato',
    normalizedArea: 'Impalato al 100%',
    histogramChart: 'Istogramma',
    histogramFrequency: 'Frequenza',
    combinationChart: 'Grafico combinato',
    columnLineCombo: 'Colonne e linee',
    AreaColumnCombo: 'Aree e colonne',

    // Charts
    pivotChartTitle: 'Grafico pivot',
    rangeChartTitle: 'Grafico di intervalli',
    settings: 'Impostazioni',
    data: 'Dati',
    format: 'Formattazione',
    categories: 'Categorie',
    defaultCategory: '(Nessuna)',
    series: 'Serie',
    xyValues: 'Valori XY',
    paired: 'Modalità combinata',
    axis: 'Assi',
    navigator: 'Navigatore',
    color: 'Colore',
    thickness: 'Spessore',
    xType: 'Tipo X',
    automatic: 'Automatico',
    category: 'Categoria',
    number: 'Numero',
    time: 'Data',
    autoRotate: 'Rotazione automatica',
    xRotation: 'Rotazione X',
    yRotation: 'Rotazione Y',
    ticks: 'Segmenti di scala',
    width: 'Larghezza',
    height: 'Altezza',
    length: 'Lunghezza',
    padding: 'Riempimento',
    spacing: 'Spaziatura',
    chart: 'Grafico',
    title: 'Titolo',
    titlePlaceholder: 'Titolo del grafico - doppio clic per modificare',
    background: 'Sfondo',
    font: 'Carattere',
    top: 'Sopra',
    right: 'Destra',
    bottom: 'Sotto',
    left: 'Sinistra',
    labels: 'Etichette',
    size: 'Dimensione',
    minSize: 'Dimensione minima',
    maxSize: 'Dimensione massima',
    legend: 'Legenda',
    position: 'Posizione',
    markerSize: 'Dimensione del marker',
    markerStroke: 'Stroke del marker',
    markerPadding: 'Riempimento del marker',
    itemSpacing: 'Spaziatura tra elementi',
    itemPaddingX: 'Riempimento orizzontale degli elementi',
    itemPaddingY: 'Riempimento verticale degli elementi',
    layoutHorizontalSpacing: 'Spaziatura orizzontale della disposizione',
    layoutVerticalSpacing: 'Spaziatura verticale della disposizione',
    strokeWidth: 'Larghezza del tratto',
    lineDash: 'Tipo di tratteggio',
    offset: 'Offset',
    offsets: 'Offset',
    tooltips: 'Descrizioni comandi',
    callout: 'Evidenziazione',
    markers: 'Marker',
    shadow: 'Ombra',
    blur: 'Sfocatura',
    xOffset: 'Offset X',
    yOffset: 'Offset Y',
    lineWidth: 'Larghezza del bordo',
    normal: 'Normale',
    bold: 'Grassetto',
    italic: 'Corsivo',
    boldItalic: 'Grassetto corsivo',
    predefined: 'Predefinito',
    fillOpacity: 'Opacità del riempimento',
    strokeOpacity: 'Opacità del bordo',
    histogramBinCount: 'Numero di intervalli',
    columnGroup: 'Colonne',
    barGroup: 'Barre',
    pieGroup: 'Torta',
    lineGroup: 'Linee',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Aree',
    histogramGroup: 'Istoregrammi',
    combinationGroup: 'Combinazione',
    groupedColumnTooltip: 'Raggruppata',
    stackedColumnTooltip: 'Impilata',
    normalizedColumnTooltip: '100% impilata',
    groupedBarTooltip: 'Raggruppata',
    stackedBarTooltip: 'Impilata',
    normalizedBarTooltip: '100% impilata',
    pieTooltip: 'Torta',
    doughnutTooltip: 'Ciambella',
    lineTooltip: 'Linea',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Impilata',
    normalizedAreaTooltip: '100% impilata',
    scatterTooltip: 'Diagramma a dispersione',
    bubbleTooltip: 'Diagramma a bolle',
    histogramTooltip: 'Isterogramma',
    columnLineComboTooltip: 'Colonne e linee',
    areaColumnComboTooltip: 'Aree e colonne',
    customComboTooltip: 'Combinazione personalizzata',
    noDataToChart: 'Nessun dato disponibile per il grafico.',
    pivotChartRequiresPivotMode: 'Il grafico pivot richiede l\'attivazione della modalità pivot.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Collegato alla griglia',
    chartUnlinkToolbarTooltip: 'Scollegato dalla griglia',
    chartDownloadToolbarTooltip: 'Scarica il grafico',
    seriesChartType: 'Tipo di grafico di serie',
    seriesType: 'Tipo di serie',
    secondaryAxis: 'Assi secondari',

    // ARIA
    ariaAdvancedFilterBuilderItem: '${variable}. Livello ${variable}. Premere INVIO per modificare.',
    ariaAdvancedFilterBuilderItemValidation: '${variable}. Livello ${variable}. ${variable} Premere INVIO per modificare.',
    ariaAdvancedFilterBuilderList: 'Elenco del costruttore di filtri avanzati',
    ariaAdvancedFilterBuilderFilterItem: 'Condizione di filtro',
    ariaAdvancedFilterBuilderGroupItem: 'Gruppo di filtri',
    ariaAdvancedFilterBuilderColumn: 'Colonna',
    ariaAdvancedFilterBuilderOption: 'Opzione',
    ariaAdvancedFilterBuilderValueP: 'Valore',
    ariaAdvancedFilterBuilderJoinOperator: 'Operatore di unione',
    ariaAdvancedFilterInput: 'Input filtro avanzato',
    ariaChecked: 'selezionato',
    ariaColumn: 'Colonna',
    ariaColumnGroup: 'Gruppo di colonne',
    ariaColumnFiltered: 'Colonna filtrata',
    ariaColumnSelectAll: 'Attiva/Disattiva seleziona tutte le colonne',
    ariaDateFilterInput: 'Input filtro data',
    ariaDefaultListName: 'Lista',
    ariaFilterColumnsInput: 'Input colonne filtro',
    ariaFilterFromValue: 'Filtra dal valore',
    ariaFilterInput: 'Input filtro',
    ariaFilterList: 'Elenco filtri',
    ariaFilterToValue: 'Filtra al valore',
    ariaFilterValue: 'Valore del filtro',
    ariaFilterMenuOpen: 'Apri menu filtro',
    ariaFilteringOperator: 'Operatore di filtraggio',
    ariaHidden: 'nascosto',
    ariaIndeterminate: 'indeterminato',
    ariaInputEditor: 'Editor di input',
    ariaMenuColumn: 'Premi CTRL + INVIO per aprire il menu della colonna.',
    ariaRowDeselect: 'Premi SPAZIO per deselezionare questa riga',
    ariaRowSelectAll: 'Premi SPAZIO per attivare/disattivare la selezione di tutte le righe',
    ariaRowToggleSelection: 'Premi SPAZIO per attivare/disattivare la selezione della riga',
    ariaRowSelect: 'Premi SPAZIO per selezionare questa riga',
    ariaSearch: 'Cerca',
    ariaSortableColumn: 'Premi INVIO per ordinare',
    ariaToggleVisibility: 'Premi SPAZIO per attivare/disattivare la visibilità',
    ariaToggleCellValue: 'Premi SPAZIO per attivare/disattivare il valore della cella',
    ariaUnchecked: 'non selezionato',
    ariaVisible: 'visibile',
    ariaSearchFilterValues: 'Cerca valori di filtro',
    ariaPageSizeSelectorLabel: 'Dimensione pagina',

    // ARIA Labels for Drop Zones
    ariaRowGroupDropZonePanelLabel: 'Gruppi di riga',
    ariaValuesDropZonePanelLabel: 'Valori',
    ariaPivotDropZonePanelLabel: 'Etichette delle colonne',
    ariaDropZoneColumnComponentDescription: 'Premi CANC per rimuovere',
    ariaDropZoneColumnValueItemDescription: 'Premi INVIO per modificare il tipo di aggregazione',
    ariaDropZoneColumnGroupItemDescription: 'Premi INVIO per ordinare',
    // utilizzato per la zona di rilascio aggregata, formato: {aggregazione}{ariaDropZoneColumnComponentAggFuncSeparator}{nome colonna}
    ariaDropZoneColumnComponentAggFuncSeparator: ' di ',
    ariaDropZoneColumnComponentSortAscending: 'ascendente',
    ariaDropZoneColumnComponentSortDescending: 'discendente',

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: 'Menu colonne',
    ariaLabelCellEditor: 'Editor cella',
    ariaLabelDialog: 'Dialogo',
    ariaLabelSelectField: 'Campo di selezione',
    ariaLabelRichSelectField: 'Campo di selezione avanzato',
    ariaLabelTooltip: 'Guida in linea',
    ariaLabelContextMenu: 'Menu contestuale',
    ariaLabelSubMenu: 'Sottomenu',
    ariaLabelAggregationFunction: 'Funzione di aggregazione',
    ariaLabelAdvancedFilterAutocomplete: 'Completamento automatico filtro avanzato',
    ariaLabelAdvancedFilterBuilderAddField: 'Aggiungi campo al costruttore di filtro avanzato',
    ariaLabelAdvancedFilterBuilderColumnSelectField: 'Seleziona colonna nel costruttore di filtro avanzato',
    ariaLabelAdvancedFilterBuilderOptionSelectField: 'Seleziona opzione nel costruttore di filtro avanzato',
    ariaLabelAdvancedFilterBuilderJoinSelectField: 'Seleziona operatore di unione nel costruttore di filtro avanzato',

    // ARIA Labels for the Side Bar
    ariaColumnPanelList: 'Elenco colonne',
    ariaFilterPanelList: 'Elenco filtri',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: '.',
    decimalSeparator: ',',

    // Data types
    true: 'Vero',
    false: 'Falso',
    invalidDate: 'Data non valida',
    invalidNumber: 'Numero non valido',
    january: 'Gennaio',
    february: 'Febbraio',
    march: 'Marzo',
    april: 'Aprile',
    may: 'Maggio',
    june: 'Giugno',
    july: 'Luglio',
    august: 'Agosto',
    september: 'Settembre',
    october: 'Ottobre',
    november: 'Novembre',
    december: 'Dicembre',
}

export default AG_GRID_LOCALE_IT;