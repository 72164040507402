import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";
import { useUser } from "../stores/useUser";

const Caronte = () => {

    const token = useUser((state) => state.token);
    const navigate = useNavigate();

    const anime_prave = (token) => {
        setTimeout(()=>{
            if (token) {
                navigate("/app");
            } else {
                navigate("/login");
            }
        }, 1000);
    }

    useEffect(() => {
        anime_prave(token);
    }, [token]);

    return (
        <div className="w-full h-full flex items-center justify-center">
            <img src={logo} className="w-1/5 animate-ping" alt="logo" />
        </div>
    );
};

export default Caronte;