import React, { memo, useMemo, useState } from 'react';
import PageBody from "../components/PageBody";
import AutoSizer from "react-virtualized-auto-sizer";
import { useAziende } from "../stores/useAziende";
import { useSocieta } from "../stores/useSocieta";
import { useCommesse } from "../stores/useCommesse";
import { useCategorie } from "../stores/useCategorie";
import { useDocumenti } from "../stores/useDocumenti";
import { usePratiche } from "../stores/usePratiche";
import { DocumentDownload, Building, Bag, Money, Category2, DocumentText } from 'iconsax-react';

export const AziendaBtn = ({ name }) => <div className="flex flex-row items-center gap-2"><Building size="22" color="#94a3b8" variant="Bulk" /><span className="truncate">{name}</span></div>
export const SocietaBtn = ({ name }) => <div className="flex flex-row items-center gap-2"><Bag size="22" color="#94a3b8" variant="Bulk" /><span className="truncate">{name}</span></div>
export const CommessaBtn = ({ name }) => <div className="flex flex-row items-center gap-2"><Money size="22" color="#94a3b8" variant="Bulk" /><span className="truncate">{name}</span></div>
export const CategorieBtn = ({ name }) => <div className="flex flex-row items-center gap-2"><Category2 size="22" color="#94a3b8" variant="Bulk" /><span className="truncate">{name}</span></div>
export const DocumentiBtn = ({ name }) => <div className="flex flex-row items-center gap-2"><DocumentText size="22" color="#94a3b8" variant="Bulk" /><span className="truncate">{name}</span></div>
export const ScaricaBtn = ({ name }) => <a rel="noreferrer" target="_blank" href={name} className="flex flex-row items-center gap-2"><DocumentDownload size="22" color="#94a3b8" variant="Bulk" /><span className="truncate">{name.split("/").at(-1).split("-").at(0)}</span></a>

export const Row = ({ title, selected, select }) => (
    <div
        onClick={() => { if (select) { select() } }}
        className="cursor-pointer px-2 py-4 border-b border-b-futura-bordoTab hover:bg-futura-bordoTab"
        style={selected ? { backgroundColor: '#0284c7' } : {}}
    >
        <div className="w-full">{title}</div>
    </div>
);



const Esplora = () => {

    const aziende = useAziende((state) => state.aziende);
    const societa = useSocieta((state) => state.societa);
    const commesse = useCommesse((state) => state.commesse);
    const categorie = useCategorie((state) => state.categorie);
    const documenti = useDocumenti((state) => state.documenti);
    const pratiche = usePratiche((state) => state.pratiche);

    const [path, setPath] = useState([]);

    const _cedenti = useMemo(() => {
        if (path.length > 0) {
            const _ced = societa.filter((societa) => societa.aziende_id === path[0]);
            const __ced = _ced.map((societa) => {
                const _pratiche = pratiche.filter((pratica) => pratica?.commessa?.societa_id === societa.id);
                if (_pratiche.length === 0) {
                    return societa;
                }
                return {
                    ...societa,
                    nome: `${societa.nome} (${_pratiche.length}/${documenti.length})`
                }
            });
            return __ced;
        } else {
            return []
        }
    }, [path, societa, pratiche]);

    const _commesse = useMemo(() => {
        if (path.length > 1) {
            const _comm = commesse.filter((commessa) => commessa.societa_id === path[1]);
            const __comm = _comm.map((commessa) => {
                const _pratiche = pratiche.filter((pratica) => pratica.commessa_id === commessa.id);
                if (_pratiche.length === 0) {
                    return commessa;
                }
                return {
                    ...commessa,
                    nome: `${commessa.nome} (${_pratiche.length})`
                }
            });
            return __comm;
        } else {
            return []
        }
    }, [path, commesse, pratiche]);

    const _categorie = useMemo(() => {
        if (path.length > 2) {
            const _cat = categorie;
            const __cat = _cat.map((categoria) => {
                const _pratiche = pratiche.filter((pratica) => pratica?.documento?.categorie?.id === categoria.id);
                if (_pratiche.length === 0) {
                    return categoria;
                }
                return {
                    ...categoria,
                    nome: `${categoria.nome}`
                }
            });
            return __cat;
        } else {
            return []
        }
    }, [path, categorie, pratiche]);

    const _documenti = useMemo(() => {
        if (path.length > 3) {
            const _doc = documenti.filter((documento) => documento.categoria_id === path[3]);
            const __doc = _doc.map((documento) => {
                const _pratiche = pratiche.filter((pratica) => pratica.documento_id === documento.id);
                if (_pratiche.length === 0) {
                    return documento;
                }
                return {
                    ...documento,
                    nome: `${documento.nome}`
                }
            });
            return __doc;
        } else {
            return []
        }
    }, [path, documenti, pratiche]);

    const _pratiche = useMemo(() => {
        if (path.length > 4) {
            return pratiche.filter((pratica) =>
                pratica.documento_id === path[4] &&
                pratica.commessa_id === path[2]
            );
        } else {
            return []
        }
    }, [path, pratiche]);


    return (
        <PageBody>
            <AutoSizer>
                {({ height, width }) => (
                    <div style={{ height, width }} className="flex flex-row gap-1">
                        <div className="flex flex-1 flex-col">
                            <div className="flex flex-1 flex-col border border-futura-bordoTab overflow-y-auto">
                                {aziende.map((item, index) => <Row
                                    key={index}
                                    title={<AziendaBtn name={item.name} />}
                                    selected={path.length > 0 && path[0] === item.id}
                                    select={() => setPath([item.id])}
                                />)}
                            </div>
                        </div>
                        <div className="flex flex-1 h-full overflow-y-auto">
                            <div className="flex flex-1 flex-col border border-futura-bordoTab overflow-y-auto">
                                {_cedenti.map((item, index) => <Row
                                    key={index}
                                    title={<SocietaBtn name={item.nome} />}
                                    selected={path.length > 1 && path[1] === item.id}
                                    select={() => setPath([path[0], item.id])}
                                />)}
                            </div>
                        </div>
                        <div className="flex flex-1 h-full overflow-y-auto">
                            <div className="flex flex-1 flex-col border border-futura-bordoTab overflow-y-auto">
                                {_commesse.map((item, index) => <Row
                                    key={index}
                                    title={<CommessaBtn name={item.nome} />}
                                    selected={path.length > 2 && path[2] === item.id}
                                    select={() => setPath([path[0], path[1], item.id])}
                                />)}
                            </div>
                        </div>
                        <div className="flex flex-1 h-full overflow-y-auto">
                            <div className="flex flex-1 flex-col border border-futura-bordoTab overflow-y-auto">
                                {_categorie.map((item, index) => <Row
                                    key={index}
                                    title={<CategorieBtn name={item.nome} />}
                                    selected={path.length > 3 && path[3] === item.id}
                                    select={() => setPath([path[0], path[1], path[2], item.id])}
                                />)}
                            </div>
                        </div>
                        <div className="flex flex-1 h-full overflow-y-auto">
                            <div className="flex flex-1 flex-col border border-futura-bordoTab overflow-y-auto">
                                {_documenti.map((item, index) => <Row
                                    key={index}
                                    title={<DocumentiBtn name={item.nome} />}
                                    selected={path.length > 4 && path[4] === item.id}
                                    select={() => setPath([path[0], path[1], path[2], path[3], item.id])}
                                />)}
                            </div>
                        </div>
                        <div className="flex flex-1 h-full overflow-y-auto">
                            <div className="flex flex-1 flex-col border border-futura-bordoTab overflow-y-auto">
                                {_pratiche.map((item, index) => <Row
                                    key={index}
                                    title={<ScaricaBtn name={`${process.env.REACT_APP_URL}${item?.file}`} />}
                                    selected={path.length > 5 && path[5] === item.id}
                                />)}
                            </div>
                        </div>
                    </div>
                )}
            </AutoSizer>
        </PageBody>
    );
}

export default memo(Esplora);