import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { _delete, _get, _post } from "../components/fetchers";
import storage from "./IDB";

export const useCategorie = create(
  persist(
    (set, get) => ({
      categorie: [],
      retrieve: async (token) => {
        try {
          const _categorie = await _get(token, "categorie");
          set({ categorie: _categorie });
        } catch (error) {
          throw new Error(error);
        }
      },
      create: async (token, body) => {
        try {
          const _categorie = await _post(token, "categorie", body);
          if (_categorie) {
            const { retrieve } = get();
            retrieve(token);
          }
        } catch (error) {
          throw new Error(error);
        }
      },
      remove: async (token, id) => {
        try {
          const _categorie = await _delete(token, "categorie", id);
          if (_categorie) {
            const { retrieve } = get();
            retrieve(token);
          }
        } catch (error) {
          throw new Error(error);
        }
      },
    }),
    {
      name: "categorie-storage",
      storage: createJSONStorage(() => storage),
    }
  )
);
