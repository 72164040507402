const BTNElimina = ({ data }) => (
  <button
    onClick={data.Elimina}
    className="bg-red-400 px-2 h-6 leading-6 rounded text-white"
  >
    Elimina
  </button>
);

export default BTNElimina;
