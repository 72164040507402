import { create } from "zustand";
import { _get, _post, _upload } from '../components/fetchers';

const useInserisci = create(
    (set, get) => ({
        isAuthenticated: false,
        token: null,
        pratiche: [],
        categorie: [],
        documenti: [],
        setIsAuthenticated: (isAuthenticated) => set({ isAuthenticated }),
        doAuthenticate: async (id, pin) => {
            try {
                const login = await _post(`${id}:${pin}`, "carica/check");
                if (login) {
                    set({ isAuthenticated: true, token: `${id}:${pin}` });
                    return login;
                } else {
                    throw new Error("Pin errato");
                }
            } catch (error) {
                throw new Error("Pin errato");
            }
        },
        retrieve: async () => {
            try {
                const { token } = get()
                const pratiche = await _get(token, "carica/pratiche");
                const categorie = await _get(token, "carica/categorie");
                const documenti = await _get(token, "carica/documenti");
                set({ pratiche, categorie, documenti });
            } catch (error) {
                console.log(error);
                throw new Error("Pin errato");
            }
        },
        postCommessa: async (body) => {
            try {
                const { token, retrieve } = get()
                const commessa = await _post(token, "carica/commessa", body);
                if (commessa) {
                    retrieve()
                }
            } catch (error) {
                throw new Error("Pin errato");
            }
        },
        postPratica: async (body) => {
            try {
                const { token, retrieve } = get()
                const categoria = await _post(token, "carica/pratica", body);
                if (categoria) {
                    retrieve()
                }
            } catch (error) {
                throw new Error("Pin errato");
            }
        },
        upload: async (file) => {
            try {
                const { token } = get()
                const up = await _upload(token, "carica/upload", file)
                if (up) {
                    return up
                } else {
                    throw new Error()
                }
            } catch (error) {
                throw new Error(error)
            }
        }
    })
)

export default useInserisci;