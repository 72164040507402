import format from "date-fns/format";
import { create } from "zustand";
import { createJSONStorage, persist } from 'zustand/middleware';
import { _get, _post } from '../components/fetchers';
import { toast } from "react-hot-toast";
import storage from "./IDB";

const useDashboard = create(
    persist(
        (set, get) => ({
            year: parseInt(format(new Date(), "yyyy")),
            f24: {},
            fatturato: {},
            percentAbbatimento: {},
            f24Fatt: {},
            lastUpdate: null,
            setYear: (year) => set({ year }),
            setF24: (azienda, anno, importo) => {
                const f24 = get().f24;
                if (!f24[azienda]) f24[azienda] = {};
                f24[azienda][anno] = importo;
                set({ f24: { ...f24 } });
                set({ lastUpdate: new Date() });
            },
            setFatturato: (azienda, anno, importo) => {
                const fatturato = get().fatturato;
                if (!fatturato[azienda]) fatturato[azienda] = {};
                fatturato[azienda][anno] = importo;
                set({ fatturato: { ...fatturato } });
                set({ lastUpdate: new Date() });
            },
            setPercentAbbatimento: (azienda, anno, importo) => {
                const percentAbbatimento = get().percentAbbatimento;
                if (!percentAbbatimento[azienda]) percentAbbatimento[azienda] = {};
                percentAbbatimento[azienda][anno] = importo;
                set({ percentAbbatimento: { ...percentAbbatimento } });
                set({ lastUpdate: new Date() });
            },
            setF24Fatt: (azienda, anno, importo) => {
                const f24Fatt = get().f24Fatt;
                if (!f24Fatt[azienda]) f24Fatt[azienda] = {};
                f24Fatt[azienda][anno] = importo;
                set({ f24Fatt: { ...f24Fatt } });
                set({ lastUpdate: new Date() });
            },
            syncUp: async (token) => {
                const confi = window.confirm("Sicuro di voler salvare i dati sul server?\nNon sarà possibile annullare l'operazione.");
                if (confi) {
                    const _f24 = get().f24;
                    const _fatturato = get().fatturato;
                    const _percentAbbatimento = get().percentAbbatimento;
                    const _f24Fatt = get().f24Fatt;
                    try {
                        await _post(token, "meta", { key: "f24", value: JSON.stringify(_f24) });
                        await _post(token, "meta", { key: "fatturato", value: JSON.stringify(_fatturato) });
                        await _post(token, "meta", { key: "percentAbbatimento", value: JSON.stringify(_percentAbbatimento) });
                        await _post(token, "meta", { key: "f24Fatt", value: JSON.stringify(_f24Fatt) });
                        set({ lastUpdate: new Date() });
                        toast.success("Dati salvati con successo.");
                    } catch (error) {
                        toast.error("Errore nel salvataggio dei dati. Riprovare più tardi.");
                    }
                }
            },
            syncDown: async (token) => {
                const confi = window.confirm("Sicuro di voler scaricare i dati dal server?\nNon sarà possibile annullare l'operazione.");
                if (confi) {
                    try {
                        const data = await _get(token, "meta");
                        if (data) {
                            data.forEach(elm => {
                                if (["f24", "fatturato", "percentAbbatimento", "f24Fatt"].includes(elm.key)) {
                                    if (elm.value !== "null" && elm.value !== null) {
                                        set({ [elm.key]: JSON.parse(elm.value) })
                                    }
                                }
                            });
                            set({ lastUpdate: new Date() })
                            toast.success("Dati scaricati con successo.");
                        }
                    } catch (error) {
                        toast.error("Errore nel download dei dati. Riprovare più tardi.");
                    }
                }
            },
        }),
        {
            name: 'dashboard-storage',
            storage: createJSONStorage(() => storage),
        }
    )
)

export default useDashboard;