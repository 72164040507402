import React, { useCallback, useEffect, useMemo, useRef } from "react";
import btnExport from "../assets/btnExport";
import BTNElimina from "../components/BTNElimina";
import PageBody from "../components/PageBody";
import Grigliata from "../components/TableNew";
import { useCategorie } from "../stores/useCategorie";
import { useDocumenti } from "../stores/useDocumenti";
import { useUser } from "../stores/useUser";

const Documenti = () => {
  const token = useUser((state) => state.token);
  const documenti = useDocumenti((state) => state.documenti);
  const retrieve = useDocumenti((state) => state.retrieve);
  const create = useDocumenti((state) => state.create);
  const remove = useDocumenti((state) => state.remove);
  const categorie = useCategorie((state) => state.categorie);

  const promptRemove = useCallback(
    (token, id, nome) => {
      if (window.confirm(`Sei sicuro di voler eliminare "${nome}"?`)) {
        remove(token, id);
      }
    },
    [remove]
  );

  const columns = [
    { field: "Categoria" },
    { field: "Documento" },
    { field: "Informazioni" },
    { field: "Necessario" },
    { field: "Multiplo" },
    { field: "Ordine" },
    { field: "Note" },
    { field: "Azioni", filter: false, sort: false, cellRenderer: BTNElimina },
  ];

  const data = useMemo(
    () =>
      documenti.map((documenti) => {
        const _categoria = categorie.find(
          (categoria) => categoria.id === documenti.categoria_id
        )?.nome;

        return {
          Id: documenti.id,
          Documento: documenti.nome,
          Informazioni: documenti.info,
          Categoria: _categoria,
          Necessario: documenti.necessario ? true : false,
          Multiplo: documenti.multiplo ? true : false,
          Ordine: documenti.ordine,
          Note: documenti.note,
          Elimina: () => promptRemove(token, documenti.id, _categoria),
        };
      }),
    [documenti, promptRemove, token, categorie]
  );

  useEffect(() => {
    if (token) {
      retrieve(token);
    }
  }, [token, retrieve]);

  const Form = () => {
    const HandleSublit = (e) => {
      e.preventDefault();
      const _categoria_id = categoria_id.current.value;
      const _nome = nome.current.value;
      const _info = info.current.value;
      const _necessario = necessario.current.checked;
      const _multiplo = multiplo.current.checked;
      const _ordine = ordine.current.value;
      const _note = note.current.value;
      if (_nome !== "" && _categoria_id) {
        const _body = {
          categoria_id: _categoria_id,
          nome: _nome,
          info: _info,
          necessario: _necessario,
          multiplo: _multiplo,
          ordine: _ordine,
          note: _note,
        };
        create(token, _body);
      }
    };

    const categoria_id = React.useRef();
    const nome = React.useRef();
    const info = React.useRef();
    const necessario = React.useRef();
    const multiplo = React.useRef();
    const ordine = React.useRef();
    const note = React.useRef();

    return (
      <form method="post" onSubmit={HandleSublit} className="flex-row">
        <p className="py-1">Nome tipologi documenti:</p>
        <input type="text" ref={nome} className="w-full border p-2" />
        <p className="py-1">Categoria:</p>
        <select ref={categoria_id} className="w-full border p-2">
          {categorie.map((categoria) => {
            return (
              <option key={categoria.id} value={categoria.id}>
                {categoria.nome}
              </option>
            );
          })}
        </select>
        <p className="py-1">Informazioni:</p>
        <input type="text" ref={info} className="w-full border p-2" />
        <p className="py-1">Necessario:</p>
        <input type="checkbox" ref={necessario} className="w-full border p-2" />
        <p className="py-1">Multiplo:</p>
        <input type="checkbox" ref={multiplo} className="w-full border p-2" />
        <p className="py-1">Ordine:</p>
        <input type="number" ref={ordine} className="w-full border p-2" />
        <p className="py-1">Note:</p>
        <input type="text" ref={note} className="w-full border p-2" />

        <button
          type="submit"
          className="bg-futura-scuro mt-6 w-full rounded border p-2 text-white"
        >
          Aggiungi
        </button>
      </form>
    );
  };

  const gridRef = useRef();

  const onBtnExport = useCallback(() => {
    btnExport(gridRef, "Tipologia Documenti", [
      "Categoria",
      "Visura",
      "Documento",
      "Informazioni",
      "Necessario",
      "Multiplo",
      "Ordine",
      "Note",
    ]);
  }, []);

  return (
    <PageBody title="Documenti">
      <Grigliata
        data={data}
        form={<Form />}
        columns={columns}
        gridRef={gridRef}
        name="Documento"
        actionCreate={create}
        onBtnExport={onBtnExport}
      />
    </PageBody>
  );
};

export default Documenti;
