import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../stores/useUser";

const Login = () => {

    const username = useRef();
    const password = useRef();
    const login = useUser((state) => state.login);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        const token = login(username.current.value, password.current.value);
        if (token) {
            navigate("/");
        }
    }

    return (
        <div className="flex w-full h-full">
            <div className="w-1/2 h-full bg-futura-sidebar">
                <div className="flex flex-col items-center justify-center h-full">
                    <h1 className="text-4xl font-bold">FuturaCare Crediti</h1>
                    <p className="text-xl">Inserisci i dati per continuare</p>
                    <form method="post" onSubmit={handleSubmit} className="flex flex-col items-center justify-center w-1/2 h-1/2 mt-10">
                        <input required ref={username} className="bg-futura-bianco rounded p-4 mb-3 border border-futura-bordoBtn" type="text" placeholder="Username" />
                        <input required ref={password} className="bg-futura-bianco rounded p-4 mb-3 border border-futura-bordoBtn" type="password" placeholder="Password" />
                        <button type="submit" className="mt-8 px-10 py-2 bg-futura-blu rounded-full text-white">Login</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;