import { RefreshCircle } from "iconsax-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Else, If, Then } from "react-if";
import { useParams } from "react-router-dom";
import AutoSizer from 'react-virtualized-auto-sizer';
import logo from "../assets/logo-bitone.svg";
import Modal from "../components/Modal";
import useInserisci from "../stores/useInserisci";
import { CategorieBtn, CommessaBtn, DocumentiBtn, Row, ScaricaBtn } from "./Esplora";

const Title = ({ title, add = true, name = "", form = <></> }) => (
    <div className="flex flex-row items-center gap-2 text-futura-bianco bg-futura-pietra p-2 content-center justify-between">
        {title}
        <If condition={add}>
            <Then>
                <Modal
                    buttonLabel={`Aggiungi ${name}`}
                    title="Aggiungi"
                    buttonClass='rounded bg-futura-blu px-4 py-2 cursor-pointer text-futura-bianco'
                >
                    {form}
                </Modal>
            </Then>
            <Else><div className="h-[40px]" /></Else>
        </If>
    </div>
)

const Inserisci = () => {

    const { codice } = useParams();
    const [pin, setPin] = useState();
    const [azienda, setAzienda] = useState();
    const _pin = useRef()
    const isAuthenticated = useInserisci((state) => state.isAuthenticated);
    const doAuthenticate = useInserisci((state) => state.doAuthenticate);
    const retrieve = useInserisci((state) => state.retrieve);
    const pratiche = useInserisci((state) => state.pratiche);
    const categorie = useInserisci((state) => state.categorie);
    const documenti = useInserisci((state) => state.documenti);
    const postCommessa = useInserisci((state) => state.postCommessa);
    const postPratica = useInserisci((state) => state.postPratica);
    const upload = useInserisci((state) => state.upload);

    useEffect(() => {
        refresh();
    }, [isAuthenticated])

    const refresh = () => {
        if (isAuthenticated) {
            retrieve([codice, pin]);
        }
    }

    const tryAuthenticate = async (e) => {
        try {
            const __pin = _pin.current.value;
            setPin(__pin);
            const auth = await doAuthenticate(codice, __pin);
            setAzienda(auth);
        } catch (e) {
            _pin.current.value = ""
            _pin.current.classList.add("bg-red-500")
            _pin.current.focus()
        }
    }

    const handleLogin = (e) => {
        e.preventDefault();
        tryAuthenticate();
    }

    const [warPath, setWarPath] = useState([]);

    const praticheFiltrate = useMemo(() => {
        if (warPath?.[0]) {
            const _p = pratiche?.filter((pratica) => pratica.id === warPath[0]);
            const _p_p = _p?.[0]?.pratiche;
            if (warPath?.[2]) {
                const _p_p_p = _p_p?.filter((pratica) => pratica.documento_id === warPath[2]);
                return _p_p_p;
            }
        }
        return [];
    }, [warPath, pratiche])


    const AddPratica = () => {

        const handleAddPratica = async (e) => {
            e.preventDefault();
            const body = {
                societa_id: azienda.id,
                nome: __nome.current.value,
                importo: __importo.current.value,
                sconto: __sconto.current.value,
                annualita_inizio: __annualita_inizio.current.value,
                quanti_anni: __quanti_anni.current.value,
                data: __data.current.value,
                modalita_acquisto: __modalita_acquisto.current.value,
                accettata: false,
                note: __note.current.value,
                codice,
                pin
            }
            try {
                const _ = await postCommessa(body);
                refresh();
            } catch (e) {
                console.log(e);
            }
        }

        const __nome = useRef();
        const __importo = useRef();
        const __sconto = useRef();
        const __annualita_inizio = useRef();
        const __quanti_anni = useRef();
        const __data = useRef();
        const __modalita_acquisto = useRef();
        const __note = useRef();

        return (
            <form onSubmit={handleAddPratica}>
                <div className="flex flex-col gap-2">
                    <p>Nome</p>
                    <input ref={__nome} type="text" className="rounded border border-futura-bordoTab px-2 py-1" />
                    <p>Importo</p>
                    <input ref={__importo} type="number" className="rounded border border-futura-bordoTab px-2 py-1" />
                    <p>Sconto</p>
                    <input ref={__sconto} type="number" className="rounded border border-futura-bordoTab px-2 py-1" />
                    <p>Anno di inizio</p>
                    <input ref={__annualita_inizio} type="number" placeholder="2023" className="rounded border border-futura-bordoTab px-2 py-1" />
                    <p>Quanti anni</p>
                    <input ref={__quanti_anni} type="number" className="rounded border border-futura-bordoTab px-2 py-1" />
                    <p>Data</p>
                    <input ref={__data} type="date" className="rounded border border-futura-bordoTab px-2 py-1" />
                    <p>Modalità acquisto</p>
                    <input ref={__modalita_acquisto} type="text" className="rounded border border-futura-bordoTab px-2 py-1" />
                    <p>Note</p>
                    <input ref={__note} type="text" className="rounded border border-futura-bordoTab px-2 py-1" />
                    <button type="submit" className="rounded bg-futura-blu px-4 py-2 cursor-pointer text-futura-bianco">Aggiungi</button>
                </div>
            </form>
        )
    }

    const AddFile = (e) => {

        const _file = useRef();
        const _note = useRef();

        const handleAddFile = async (e) => {
            e.preventDefault();
            const __file = _file.current.files;
            const __note = _note.current.value;
            const fIlEs = Array.prototype.slice.call(__file) || [];
            if (fIlEs.length > 0) {
                fIlEs.forEach(async (f) => {
                    try {
                        const _uppiti = await upload(f);
                        const _body = {
                            documento_id: warPath[2],
                            commessa_id: warPath[0],
                            file: _uppiti.filePath,
                            accettato: false,
                            note: __note
                        }
                        postPratica(_body);
                        refresh();
                    } catch (error) {
                    }
                })
            }
            refresh();
        }

        return (
            <form onSubmit={handleAddFile}>
                <div className="flex flex-col gap-2">
                    <p>File</p>
                    <input ref={_file} type="file" multiple className="w-full rounded border p-2" placeholder="File" />
                    <p>Note</p>
                    <input ref={_note} type="text" className="w-full rounded border p-2" placeholder="Note" />
                    <button type="submit" className="rounded bg-futura-blu px-4 py-2 cursor-pointer text-futura-bianco">Aggiungi</button>
                </div>
            </form>
        )
    }

    return (
        <div className="w-screen h-screen flex content-center flex-col">
            <div className="flex items-center content-center justify-between">
                <div className="flex items-center gap-2 text-white px-2 rounded content-center">
                    <img src={logo} alt="logo" />
                    FuturaCare crediti
                    <If condition={azienda}>
                        <Then>
                            <span>- <strong>{azienda?.nome}</strong> per <strong>{azienda?.azienda?.name}</strong></span>
                        </Then>
                        <Else><></></Else>
                    </If>
                </div>
                <If condition={azienda}>
                    <Then>
                        <div>
                            <RefreshCircle size="40" color="#fff" variant="Bold" className="cursor-pointer mx-2" onClick={refresh} />
                        </div>
                    </Then>
                    <Else><></></Else>
                </If>
            </div>
            <div className="flex flex-1 p-2">
                <AutoSizer>
                    {({ height, width }) => (
                        <If condition={isAuthenticated}>
                            <Then>
                                <div style={{ height, width }} className="flex flex-row gap-1">
                                    <div className="flex flex-1 flex-col">
                                        <Title title="Pratiche" name="pratica" form={<AddPratica />} />
                                        <div className="flex flex-1 flex-col border bg-white border-futura-bordoTab overflow-y-auto">
                                            {
                                                pratiche && Array.isArray(pratiche) && pratiche.map((pratica) => (
                                                    <Row
                                                        key={pratica.id}
                                                        title={<CommessaBtn name={`${pratica.nome} (${pratica.pratiche.length}/${documenti.length})`} />}
                                                        selected={warPath?.[0] === pratica.id}
                                                        select={() => setWarPath([pratica.id])}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="flex flex-1 flex-col">
                                        <Title title="Categorie documentali" add={false} />
                                        <div className="flex flex-1 flex-col border bg-white border-futura-bordoTab overflow-y-auto">
                                            {
                                                warPath.length > 0 && Array.isArray(categorie) && categorie.map((categoria) => (
                                                    <Row
                                                        key={categoria.id}
                                                        title={<CategorieBtn name={categoria.nome} />}
                                                        selected={warPath?.[1] === categoria.id}
                                                        select={() => setWarPath([warPath?.[0], categoria.id])}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="flex flex-1 flex-col">
                                        <Title title="Documenti" add={false} />
                                        <div className="flex flex-1 flex-col border bg-white border-futura-bordoTab overflow-y-auto">
                                            {
                                                warPath.length > 1 && Array.isArray(categorie) && documenti
                                                    .filter((documento) => documento.categoria_id === warPath?.[1])
                                                    .map((documento) => (
                                                        <Row
                                                            key={documento.id}
                                                            title={<DocumentiBtn name={documento.nome} />}
                                                            selected={warPath?.[2] === documento.id}
                                                            select={() => setWarPath([warPath?.[0], warPath?.[1], documento.id])}
                                                        />
                                                    ))
                                            }
                                        </div>
                                    </div>
                                    <div className="flex flex-1 flex-col">
                                        <Title title="File caricati" add={warPath.length > 2} name="documento" form={<AddFile />} />
                                        <div className="flex flex-1 flex-col border bg-white border-futura-bordoTab overflow-y-auto">
                                            {
                                                warPath.length > 2 && Array.isArray(praticheFiltrate) && praticheFiltrate.map((pratica) => (
                                                    <Row
                                                        key={pratica.id}
                                                        title={<ScaricaBtn name={`${process.env.REACT_APP_URL}${pratica?.file}`} />}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Then>
                            <Else>
                                <div style={{ height, width }} className="flex justify-center items-center">
                                    <form method="post" onSubmit={handleLogin} className="bg-white rounded p-6 w-64 flex-row">
                                        <p className="font-bold pb-4">Autenticazione richiesta:</p>
                                        <p className="py-1">Inserire il pin:</p>
                                        <input ref={_pin} type="password" placeholder="Pin" className="w-full rounded border p-2" />
                                        <button type="submit"
                                            className="bg-futura-scuro mt-6 w-full rounded border p-2 text-white">
                                            Accedi
                                        </button>
                                    </form>
                                </div>
                            </Else>
                        </If>
                    )}
                </AutoSizer>
            </div>
        </div>
    );
}

export default Inserisci;